import React from 'react';
import styled from '@emotion/styled';
import Select from '../../components/common/Select/Select';
import { useNavigate } from 'react-router-dom';
import { Shipment, ShipmentStatus } from '../../types/models/shipment';

interface ShipmentTableProps {
  type: 'inbound' | 'outbound';
  data: Array<Shipment>;
  starredShipments: string[];
  onToggleStar: (trackingNumber: string) => void;
  onSampleClick: (sampleId: string) => void;
}

interface StatusProps {
  status: ShipmentStatus | string | undefined;
  children: React.ReactNode;
}

interface StatusBadgeProps {
  status: string;
}

const getStatusColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case ShipmentStatus.CANCELLED.toLowerCase():
      return '#FF0000';
    case ShipmentStatus.IN_TRANSIT.toLowerCase():
      return '#FFA500';
    case ShipmentStatus.DELIVERED.toLowerCase():
      return '#00A651';
    case ShipmentStatus.PENDING.toLowerCase():
      return '#666666';
    default:
      return '#000000';
  }
};

const Status: React.FC<StatusProps> = ({ status, children }) => {
  const getStatusString = (status: ShipmentStatus | string | undefined): string => {
    if (!status) return '';
    if (typeof status === 'string') return status;
    return status as string;
  };

  const statusString = getStatusString(status);

  return (
    <StatusBadge status={statusString}>{children}</StatusBadge>
  );
};

const ShipmentTable: React.FC<ShipmentTableProps> = ({ 
  type, 
  data, 
  starredShipments,
  onToggleStar,
  onSampleClick
}) => {
  const navigate = useNavigate();

  // Define options for each select
  const trialOptions = [
    { value: 'evgr-a-001', label: 'EVGR-A-001' },
    { value: 'evgr-a-002', label: 'EVGR-A-002' }
  ];

  const statusOptions = [
    { value: 'on-time', label: 'On Time' },
    { value: 'delayed', label: 'Delayed' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'failed-pickup', label: 'Failed Pickup' }
  ];

  const timeOptions = [
    { value: 'today', label: 'Today' },
    { value: 'tomorrow', label: 'Tomorrow' },
    { value: 'next-week', label: 'Next Week' }
  ];

  const priorityOptions = [
    { value: 'high', label: 'High Priority' },
    { value: 'medium', label: 'Medium Priority' },
    { value: 'low', label: 'Low Priority' }
  ];

  return (
    <TableContainer>
      <TableHeader>
        <TableTitle>{type === 'inbound' ? 'Inbound' : 'Outbound'} Shipments</TableTitle>
        <FilterContainer>
          <Select 
            placeholder="Trial"
            options={trialOptions}
          />
          <Select 
            placeholder="Status"
            options={statusOptions}
          />
          {type === 'inbound' ? (
            <Select 
              placeholder="Sample Creation Time"
              options={timeOptions}
            />
          ) : (
            <>
              <Select 
                placeholder="Priority"
                options={priorityOptions}
              />
              <Select 
                placeholder="Expected Arrival Time"
                options={timeOptions}
              />
              <SeeAllButton>See All</SeeAllButton>
            </>
          )}
        </FilterContainer>
      </TableHeader>

      <Table>
        <thead>
          <tr>
            <Th>Tracking #</Th>
            <Th>{type === 'inbound' ? 'Origin' : 'Destination'}</Th>
            <Th>Study</Th>
            {type === 'outbound' && <Th>SKU</Th>}
            <Th>Status</Th>
            <Th>Expected Arrival</Th>
            {type === 'inbound' && <Th>Sample Id[s]</Th>}
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {data.map((shipment, index) => (
            <tr key={`${shipment.tracking_number}-${index}`}>
              <Td>{shipment.tracking_number}</Td>
              <Td>{type === 'inbound' ? shipment.origin?.city : shipment.destination?.city}</Td>
              <Td>{shipment.trial?.name}</Td>
              {type === 'outbound' && <Td>{shipment.contents?.[0]?.kit_name}</Td>}
              <Td>
                <Status status={shipment.status}>
                  {shipment.status}
                </Status>
              </Td>
              <Td>{shipment.expected_delivery}</Td>
              {type === 'inbound' && (
                <Td>
                  {shipment.sample_ids?.map(id => (
                    <SampleId 
                      key={id}
                      onClick={() => navigate(`/shipment-manager/${id}`)}
                    >
                      {id}
                    </SampleId>
                  ))}
                </Td>
              )}
              <Td>
                <StarButton 
                  onClick={() => onToggleStar(shipment.tracking_number)}
                  isStarred={starredShipments.includes(shipment.tracking_number)}
                >
                  ★
                </StarButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

// Styled components...
const TableContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  border: 1px solid #000;
`;

const TableHeader = styled.div`
  margin-bottom: 24px;
`;

const TableTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
`;

const StatusBadge = styled.span<StatusBadgeProps>`
  color: ${props => getStatusColor(props.status)};
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
`;

const SampleId = styled.span`
  background: #F5F5F5;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  
  &:hover {
    background: #E0E0E0;
  }
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#000' : '#E0E0E0'};
  font-size: 16px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#666' : '#000'};
  }
`;

const SeeAllButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
`;

export default ShipmentTable; 