import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import globeImage from '../../assets/shipmentmanagerglobe.png';
import ShipmentTable from '../../components/Shipments/ShipmentTable';
import { ShipmentService, useShipments } from '../../api/endpoints/shipments';
import type { Shipment } from '../../types/models/shipment';

interface MockShipment {
  id: string;
  tracking_number: string;
  status: string;
  trial: {
    id: string;
    name: string;
  };
  origin: {
    city: string;
    state: string;
  };
  destination: {
    city: string;
    state: string;
  };
  contents: Array<{
    kit_name: string;
    quantity: number;
  }>;
  service_type: string;
  carrier: string;
  cost: number;
  requires_signature: boolean;
  hold_at_location: boolean;
  sample_ids: string[];
  created_at: string;
  parcels: any[];
}

const ShipmentManager: React.FC = () => {
  const navigate = useNavigate();
  const [starredShipments, setStarredShipments] = useState<string[]>([]);
  
  // Instead of using useShipments, let's create mock data
  const mockData = {
    data: {
      inboundData: [
        {
          id: 'inbound-1',
          tracking_number: 'IN123456789',
          status: 'PENDING',
          trial: { id: 'trial-1', name: 'Trial A' },
          origin: { city: 'Seattle', state: 'WA' },
          destination: { city: 'Boston', state: 'MA' },
          contents: [{ kit_name: 'Box and Timestrip v0.2', quantity: 1 }],
          service_type: 'FedEx Priority Overnight',
          carrier: 'FedEx',
          cost: 45.99,
          requires_signature: true,
          hold_at_location: false,
          sample_ids: ['SAMPLE-001'],
          created_at: new Date().toISOString(),
          parcels: []
        },
        {
          id: 'inbound-2',
          tracking_number: 'IN987654321',
          status: 'IN_TRANSIT',
          trial: { id: 'trial-1', name: 'Trial A' },
          origin: { city: 'Chicago', state: 'IL' },
          destination: { city: 'Boston', state: 'MA' },
          contents: [{ kit_name: 'Box and Timestrip v0.2', quantity: 1 }],
          service_type: 'FedEx Priority Overnight',
          carrier: 'FedEx',
          cost: 45.99,
          requires_signature: true,
          hold_at_location: false,
          sample_ids: ['SAMPLE-002'],
          created_at: new Date().toISOString(),
          parcels: []
        }
      ],
      outboundData: [
        {
          id: 'outbound-1',
          tracking_number: 'OUT123456789',
          status: 'DELIVERED',
          trial: { id: 'trial-1', name: 'Trial A' },
          origin: { city: 'Boston', state: 'MA' },
          destination: { city: 'Seattle', state: 'WA' },
          contents: [{ kit_name: 'Box and Timestrip v0.2', quantity: 1 }],
          service_type: 'FedEx Express Saver',
          carrier: 'FedEx',
          cost: 35.99,
          requires_signature: true,
          hold_at_location: false,
          sample_ids: ['SAMPLE-003'],
          created_at: new Date().toISOString(),
          parcels: []
        }
      ]
    }
  };

  const handleToggleStar = async (trackingNumber: string) => {
    try {
      await ShipmentService.toggleStar(trackingNumber);
      setStarredShipments(prev => 
        prev.includes(trackingNumber)
          ? prev.filter(t => t !== trackingNumber)
          : [...prev, trackingNumber]
      );
    } catch (error) {
      console.error('Error toggling star:', error);
    }
  };

  const findShipmentBySampleId = (sampleId: string): MockShipment | undefined => {
    return mockData.data.inboundData.find(shipment => 
      shipment.sample_ids?.includes(sampleId)
    ) || mockData.data.outboundData.find(shipment => 
      shipment.sample_ids?.includes(sampleId)
    );
  };

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Shipment Manager</Title>
          
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <GlobeImage src={globeImage} alt="Global shipment map" />
        
        <ShipmentTable 
          type="inbound" 
          data={mockData.data.inboundData as any} 
          starredShipments={starredShipments}
          onToggleStar={handleToggleStar}
          onSampleClick={(sampleId) => {
            const shipment = findShipmentBySampleId(sampleId);
            navigate(`/shipment-manager/${sampleId}`, { state: { shipment } });
          }}
        />
        <ShipmentTable 
          type="outbound" 
          data={mockData.data.outboundData as any} 
          starredShipments={starredShipments}
          onToggleStar={handleToggleStar}
          onSampleClick={(sampleId) => {
            const shipment = findShipmentBySampleId(sampleId);
            navigate(`/shipment-manager/${sampleId}`, { state: { shipment } });
          }}
        />
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const GlobeImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export default ShipmentManager; 