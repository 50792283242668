import React from 'react';
import styled from '@emotion/styled';
import odysseyIcon from '../../../assets/odyssey-icon.png';
import odysseyLogo from '../../../assets/odyssey.png';

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <IconImage src={odysseyIcon} alt="" />
        <LogoImage src={odysseyLogo} alt="Odyssey" />
      </LogoContainer>
      <SearchBar
        type="text"
        placeholder="Search..."
      />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #E0E0E0;
  background: white;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 24px;
`;

const IconImage = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
`;

const LogoImage = styled.img`
  height: 24px;
  width: auto;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
`;

const SearchBar = styled.input`
  flex: 1;
  max-width: 600px;
  padding: 8px 16px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #000;
  }

  &::placeholder {
    color: #666;
  }
`;

export default Header; 