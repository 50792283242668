import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';
import ShipmentSummaryCard from '../../components/Shipment/ShipmentSummaryCard';
import NextButton from '../../components/common/Button/NextButton';
import { colors } from '../../components/constants/breakpoints';
import BillingSection from '../../components/Billing/BillingSection';


const ConfirmationAndBilling: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Handle submission logic here
    console.log('Submitting shipment...');
    // Navigate to confirmed page after submission
    navigate('/send-kits/confirmed');
  };

  const handleEdit = () => {
    navigate('/send-kits');
  };

  const handleBillingChange = (billingData: {
    billTo: string;
    accountId: string;
    invoiceEmails: string[];
  }) => {
    // Handle billing data changes
    console.log('Billing data updated:', billingData);
  };

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader />
        
        <Section>
          <SectionTitle>Outbound Shipment</SectionTitle>
          <ShipmentSummaryCard
            type="outbound"
            date="October 5, 2024"
            quantity="4x"
            trialId="2124--01-9876"
            address={{
              name: "Girradheli Clinic",
              street: "900 North Point St F301",
              cityStateZip: "San Francisco, CA 94109"
            }}
            service={{
              name: "FedEx 2Day",
              time: "Delivers by 4:30pm N(2)BD"
            }}
            contents={[
              { name: "Tasso+ Blood Collection Device", quantity: 1 },
              { name: "RC-5 Disposable Temp Logger", quantity: 1 },
              { name: "Gel Packs", quantity: 4 }
            ]}
            estimatedCost="$54.43"
            onEdit={handleEdit}
          />
        </Section>

        <Section>
          <SectionTitle>Inbound Shipment(s)</SectionTitle>
          <ShipmentSummaryCard
            type="inbound"
            date="October 12, 2024" // TODO: Get from previous view
            quantity="4x" // TODO: Get from previous view
            trialId="2124--01-9876" // TODO: Get from previous view
            address={{
              name: "David Brown [ImYoo]", // TODO: Get from previous view
              street: "329 Oyster Point Blvd, 3rd Floor", // TODO: Get from previous view 
              cityStateZip: "San Francisco, CA 94080" // TODO: Get from previous view
            }}
            service={{
              name: "FedEx Overnight", // TODO: Get from previous view
              time: "10:30am PT NBD" // TODO: Get from previous view
            }}
            contents={[
              { name: "Tasso+ Blood Collection Device", quantity: 1 }, // TODO: Get from previous view
              { name: "RC-5 Disposable Temp Logger", quantity: 1 }, // TODO: Get from previous view
              { name: "Gel Packs", quantity: 4 } // TODO: Get from previous view
            ]}
            estimatedCost="$54.43/each" // TODO: Get from previous view
            onEdit={handleEdit}
          />
        </Section>

        <BillingSection onBillingChange={handleBillingChange} />

        <ButtonContainer>
          <NextButton onClick={handleSubmit}>Submit</NextButton>
        </ButtonContainer>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text.primary};
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 0.0625rem solid ${colors.border};
`;

export default ConfirmationAndBilling; 