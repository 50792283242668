import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (!process.env.REACT_APP_SUPABASE_URL || !process.env.REACT_APP_SUPABASE_ANON_KEY) {
  throw new Error(
    'Please define REACT_APP_SUPABASE_URL and REACT_APP_SUPABASE_ANON_KEY environment variables'
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 