export const breakpoints = {
  sm: '30rem',    // 480px
  md: '48rem',    // 768px
  lg: '64rem',    // 1024px
  xl: '80rem'     // 1280px
} as const;

export const colors = {
  primary: '#007AFF',
  border: '#E0E0E0',
  text: {
    primary: '#333',
    secondary: '#666',
    placeholder: '#999'
  }
} as const; 