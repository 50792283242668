import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import api from '../../api/client';
import { ENDPOINTS } from '../../api/config';
import uploadIcon from '../../assets/upload-icon.png';
import Header from '../../components/common/Header/Header';
import axios from 'axios';
import { colors } from '../../components/constants/breakpoints';

interface FileUploadState {
  file: File | null;
  isUploading: boolean;
  error: string | null;
}

const TrialSetup: React.FC = () => {
  const navigate = useNavigate();
  const [trialId, setTrialId] = useState('');
  const [idError, setIdError] = useState<string | null>(null);
  const [isChecking, setIsChecking] = useState(false);
  const [fileState, setFileState] = useState<FileUploadState>({
    file: null,
    isUploading: false,
    error: null
  });

  const checkTrialIdExists = async (id: string) => {
    if (!id.trim()) return false;
    try {
      setIsChecking(true);
      console.log('Checking trial ID at:', `${process.env.REACT_APP_API_URL}/trials/check/${id}/`);
      
      const response = await api.get(ENDPOINTS.trials.check(id));
      console.log('Trial ID check response:', response.data);
      return response.data.exists;
    } catch (error) {
      console.error('Detailed error:', error);
      if (axios.isAxiosError(error)) {
        console.error('API Error:', {
          status: error.response?.status,
          data: error.response?.data,
          config: error.config
        });
      }
      throw new Error('Failed to check trial ID');
    } finally {
      setIsChecking(false);
    }
  };

  const validateTrialId = (id: string): boolean => {
    const trialIdRegex = /^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}$/i;
    return trialIdRegex.test(id);
  };

  const formatTrialId = (input: string): string => {
    const cleaned = input.replace(/[^A-Z0-9]/gi, '').toUpperCase();
    
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else if (cleaned.length <= 9) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 9)}-${cleaned.slice(9, 12)}`;
    }
  };

  const handleTrialIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedId = formatTrialId(e.target.value);
    setTrialId(formattedId);
    setIdError(null);
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setFileState({ ...fileState, file, error: null });
    } else {
      setFileState({ ...fileState, error: 'Please upload a PDF file' });
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setFileState({ ...fileState, file, error: null });
    } else {
      setFileState({ ...fileState, error: 'Please upload a PDF file' });
    }
  };

  const handleNext = async () => {
    if (!trialId.trim()) {
      setIdError('Please enter a Trial ID');
      return;
    }

    if (!validateTrialId(trialId)) {
      setIdError('Trial ID must be in format: XXX-XXX-XXX-XXX');
      return;
    }

    try {
      setIsChecking(true);
      const exists = await checkTrialIdExists(trialId);
      
      if (exists) {
        setIdError('This Trial ID already exists');
        return;
      }

      // Only proceed if the ID doesn't exist
      const progress = {
        hasTrialId: true,
        trialId: trialId,
        pdfFile: fileState.file ? {
          name: fileState.file.name,
        } : null
      };
      
      localStorage.setItem('trialProgress', JSON.stringify(progress));
      navigate(`/create-trial/devices/${trialId}`);
      
    } catch (err) {
      console.error('Error checking trial ID:', err);
      setIdError('Error checking trial ID availability');
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <PageContainer>
      <Header />
      <MainContent>
        <Title>Create a Trial</Title>
        
        <FormSection>
          <Label>Give your Trial an ID</Label>
          <Input
            type="text"
            value={trialId}
            onChange={handleTrialIdChange}
            placeholder="XXX-XXX-XXX-XXX"
            maxLength={15}
            disabled={isChecking}
          />
          <HelperText>Format: XXX-XXX-XXX-XXX (e.g., 123-ABC-456-DEF)</HelperText>
          {isChecking && <LoadingText>Checking availability...</LoadingText>}
          {idError && <ErrorMessage>{idError}</ErrorMessage>}
        </FormSection>

        <UploadSection>
          <Label>Upload Patient IDs, Site IDs, and Associated Addresses</Label>
          <label htmlFor="file-upload" style={{ width: '100%', cursor: 'pointer' }}>
            <UploadContainer
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <UploadIcon src={uploadIcon} alt="" />
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                id="file-upload"
              />
              <UploadText>
                {fileState.file ? fileState.file.name : 'Choose a PDF file or drag it here'}
              </UploadText>
              {fileState.error && <ErrorMessage>{fileState.error}</ErrorMessage>}
            </UploadContainer>
          </label>
        </UploadSection>

        <NextButton 
          onClick={handleNext}
          disabled={fileState.isUploading}
        >
          Next
        </NextButton>
      </MainContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
  background: white;
`;

const MainContent = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
`;

const FormSection = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Label = styled.label`
  font-size: 16px;
  color: #000;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #000;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 14px;
  text-align: center;
`;

const UploadSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UploadContainer = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed #E0E0E0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px 0;

  &:hover {
    background-color: #F5F5F5;
  }
`;

const UploadIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
`;

const UploadText = styled.p`
  font-size: 16px;
  color: #000;
  text-align: center;
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 12px 24px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #000;
  }

  &:disabled {
    background-color: #E0E0E0;
    cursor: not-allowed;
  }
`;

const LoadingText = styled.p`
  font-size: 14px;
  color: #666;
  text-align: center;
  font-style: italic;
`;

const HelperText = styled.p`
  font-size: 14px;
  color: ${colors.text.secondary};
  margin-top: 4px;
`;

export default TrialSetup;