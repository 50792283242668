import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse, QueryParams } from '../../types/api';
import type { Trial, CollectionDevice, StandardShipment } from '../../types/models/trial';
import type { Site } from '../../types/models/site';
import { supabase } from '../supabase';

export const trialApi = {
  // Basic CRUD
  getTrials: async (params?: QueryParams): Promise<ApiResponse<PaginatedApiResponse<Trial>>> => {
    return api.get(ENDPOINTS.trials.base, { params });
  },

  getTrial: async (id: string): Promise<ApiResponse<Trial>> => {
    return api.get(ENDPOINTS.trials.summary(id));
  },

  createTrial: async (data: Partial<Trial>): Promise<ApiResponse<Trial>> => {
    return api.post(ENDPOINTS.trials.base, data);
  },

  updateTrial: async (id: string, data: Partial<Trial>): Promise<ApiResponse<Trial>> => {
    return api.put(ENDPOINTS.trials.byId(id), data);
  },

  deleteTrial: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.trials.summary(id));
  },

  // Devices
  getTrialDevices: async (id: string): Promise<ApiResponse<CollectionDevice[]>> => {
    return api.get(ENDPOINTS.trials.devices(id));
  },

  updateTrialDevices: async (id: string, devices: CollectionDevice[]): Promise<ApiResponse<void>> => {
    return api.put(ENDPOINTS.trials.devices(id), { devices });
  },

  // Shipments
  getTrialShipments: async (id: string): Promise<ApiResponse<StandardShipment[]>> => {
    return api.get(ENDPOINTS.trials.shipments(id));
  },

  updateTrialShipments: async (id: string, shipments: StandardShipment[]): Promise<ApiResponse<void>> => {
    return api.put(ENDPOINTS.trials.shipments(id), { shipments });
  },

  // Sites
  getTrialSites: async (id: string): Promise<ApiResponse<Site[]>> => {
    return api.get(ENDPOINTS.trials.sites(id));
  },

  updateTrialSites: async (id: string, sites: Site[]): Promise<ApiResponse<void>> => {
    return api.put(ENDPOINTS.trials.sites(id), { sites });
  },

  // File Upload
  uploadTrialFile: async (id: string, file: File): Promise<ApiResponse<void>> => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post(ENDPOINTS.trials.upload(id), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

// React Query Hooks
export const useTrials = () => {
  return useQuery<PaginatedApiResponse<Trial>>({
    queryKey: ['trials'],
    queryFn: async () => {
      const response = await api.get('/trials/');
      return response.data;
    }
  });
};

export const useTrial = (id: string) => {
  return useQuery(['trial', id], () => trialApi.getTrial(id), {
    enabled: !!id,
  });
};

export const useCreateTrial = () => {
  return useMutation(trialApi.createTrial);
};

export const useUpdateTrial = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<Trial> }) => trialApi.updateTrial(id, data)
  );
};

export const useDeleteTrial = () => {
  return useMutation(trialApi.deleteTrial);
};

export const useTrialDevices = (id: string) => {
  return useQuery(['trial', id, 'devices'], () => trialApi.getTrialDevices(id), {
    enabled: !!id,
  });
};

export const useTrialSites = (id: string) => {
  return useQuery(['trial', id, 'sites'], () => trialApi.getTrialSites(id), {
    enabled: !!id,
  });
};

export const useTrialShipments = (id: string) => {
  return useQuery(['trial', id, 'shipments'], () => trialApi.getTrialShipments(id), {
    enabled: !!id,
  });
}; 