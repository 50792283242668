interface EnvironmentConfig {
  apiBaseUrl: string;
  supabaseUrl: string;
  supabaseKey: string;
}

export const getEnvironmentConfig = (env: string): EnvironmentConfig => {
  switch (env) {
    case 'production':
      return {
        apiBaseUrl: 'https://odyssey-api-afmq.onrender.com/api',
        supabaseUrl: process.env.REACT_APP_SUPABASE_URL || 'https://ybqnyhmrqiuzzamcqvem.supabase.co',
        supabaseKey: process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlicW55aG1ycWl1enphbWNxdmVtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMyOTU0MDYsImV4cCI6MjA0ODg3MTQwNn0.7-fij5wOR0CVzIEiaG0f8ie1mLFRnLJdLR065ArlGPQ'
      };
    case 'staging':
      return {
        apiBaseUrl: 'https://odyssey-api-staging.onrender.com/api',
        supabaseUrl: process.env.REACT_APP_SUPABASE_URL || 'https://ybqnyhmrqiuzzamcqvem.supabase.co',
        supabaseKey: process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlicW55aG1ycWl1enphbWNxdmVtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMyOTU0MDYsImV4cCI6MjA0ODg3MTQwNn0.7-fij5wOR0CVzIEiaG0f8ie1mLFRnLJdLR065ArlGPQ'
      };
    default: // development
      return {
        apiBaseUrl: 'http://localhost:8000/api',
        supabaseUrl: process.env.REACT_APP_SUPABASE_URL || 'https://ybqnyhmrqiuzzamcqvem.supabase.co',
        supabaseKey: process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlicW55aG1ycWl1enphbWNxdmVtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMyOTU0MDYsImV4cCI6MjA0ODg3MTQwNn0.7-fij5wOR0CVzIEiaG0f8ie1mLFRnLJdLR065ArlGPQ'
      };
  }
}; 