import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { Shipment } from '../../types/models/shipment';
import { ShipmentService } from '../../api/endpoints/shipments';

const ShipmentProfile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchShipmentDetails = async () => {
      try {
        setIsLoading(true);
        const data = await ShipmentService.getShipmentById(id!);
        if (data) {
          setShipment(data);
        } else {
          setError('Shipment not found');
        }
      } catch (error) {
        console.error('Error fetching shipment:', error);
        setError('Failed to load shipment details');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchShipmentDetails();
    }
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>; // Replace with proper loading component
  }

  if (error || !shipment) {
    return <div>Error: {error || 'Shipment not found'}</div>; // Replace with proper error component
  }

  const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'cancelled': return '#FF0000';
      case 'delayed': return '#FFA500';
      case 'on time': return '#00A651';
      case 'failed pickup': return '#FF0000';
      default: return '#000000';
    }
  };

  const getStatusMessage = (status: string) => {
    switch (status.toLowerCase()) {
      case 'cancelled': return 'Shipment Cancelled';
      case 'delayed': return 'Shipment Delayed';
      case 'on time': return 'On Time';
      case 'failed pickup': return 'Pick up Failed';
      default: return status;
    }
  };

  const getStatusReason = (status: string) => {
    switch (status.toLowerCase()) {
      case 'cancelled': return 'Shipment was cancelled by the sender.';
      case 'delayed': return 'Package is experiencing transit delays.';
      case 'failed pickup': return 'Package not found.';
      default: return '';
    }
  };

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Shipment Profile</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickups')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <ProfileCard>
          <ProfileHeader>
            <ShipmentId>Shipment ID# {id}</ShipmentId>
            <EditButton>Edit</EditButton>
          </ProfileHeader>

          <MainContent>
            <LeftColumn>
              <Section>
                <SectionTitle>Inbound</SectionTitle>
              </Section>

              <Section>
                <SectionTitle>Package Contents</SectionTitle>
                {/* Add package contents here */}
              </Section>

              <Section>
                <SectionTitle>Overview</SectionTitle>
                <DetailItem>
                  <Label>Tracking Number:</Label>
                  <Value>788208439645</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Ship Date:</Label>
                  <Value>12/15/23</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Standard Transit:</Label>
                  <Value>3 days</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Delivered:</Label>
                  <Value>Pending</Value>
                </DetailItem>
              </Section>

              <Section>
                <SectionTitle>Package Details</SectionTitle>
                <DetailItem>
                  <Label>Weight:</Label>
                  <Value>1.5 lbs</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Total Components:</Label>
                  <Value>3</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Packaging:</Label>
                  <Value>Box</Value>
                </DetailItem>
              </Section>

              <Section>
                <SectionTitle>Service</SectionTitle>
                <DetailItem>
                  <Label>Service:</Label>
                  <Value>Ground</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Terms:</Label>
                  <Value>Standard</Value>
                </DetailItem>
              </Section>
            </LeftColumn>

            <RightColumn>
              <OrderStatus>
                <StatusHeader>
                  <StatusTitle>Order Status</StatusTitle>
                  <Location>{shipment?.origin?.city || 'Location Unknown'}</Location>
                </StatusHeader>
                <StatusDetail>
                  <DateTime>
                    <DateSpan>{new Date().toLocaleDateString('en-US', { 
                      weekday: 'long', 
                      year: '2-digit', 
                      month: '2-digit', 
                      day: '2-digit' 
                    })}</DateSpan>
                    <TimeSpan>{new Date().toLocaleTimeString('en-US', { 
                      hour: '2-digit', 
                      minute: '2-digit' 
                    })}</TimeSpan>
                  </DateTime>
                  <StatusText status={shipment?.status?.toLowerCase() || ''}>
                    {getStatusMessage(shipment?.status?.toString() || '')}
                  </StatusText>
                  {getStatusReason(shipment?.status || '') && (
                    <ReasonBox>
                      <ReasonText>Reason:</ReasonText>
                      <ReasonDetail>{getStatusReason(shipment?.status || '')}</ReasonDetail>
                    </ReasonBox>
                  )}
                </StatusDetail>
                <UpdateTempLogButton>Update Temp Log</UpdateTempLogButton>
              </OrderStatus>
            </RightColumn>
          </MainContent>
        </ProfileCard>
      </Container>
    </PageLayout>
  );
};

// Styled Components
const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ProfileCard = styled.div`
  background: white;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 24px;
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const ShipmentId = styled.h2`
  font-size: 24px;
  color: #666;
  font-weight: normal;
`;

const EditButton = styled.button`
  padding: 8px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div``;

const Section = styled.div`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 16px;
`;

const DetailItem = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Label = styled.span`
  width: 150px;
  color: #666;
`;

const Value = styled.span`
  flex: 1;
`;

const OrderStatus = styled.div`
  background: #f5f5f5;
  padding: 24px;
  border-radius: 8px;
`;

const StatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StatusTitle = styled.h3`
  font-size: 20px;
  margin: 0;
`;

const Location = styled.span`
  font-weight: 500;
`;

const StatusDetail = styled.div`
  margin-bottom: 24px;
`;

const DateTime = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
`;

const DateSpan = styled.span``;
const TimeSpan = styled.span``;

const StatusText = styled.div<{ status: string }>`
  color: ${props => {
    switch (props.status.toLowerCase()) {
      case 'cancelled': return '#FF0000';
      case 'delayed': return '#FFA500';
      case 'on time': return '#00A651';
      case 'failed pickup': return '#FF0000';
      default: return '#000000';
    }
  }};
  margin-bottom: 8px;
`;

const ReasonBox = styled.div`
  background: white;
  padding: 12px;
  border-radius: 4px;
`;

const ReasonText = styled.span`
  color: #666;
  margin-right: 8px;
`;

const ReasonDetail = styled.span``;

const UpdateTempLogButton = styled.button`
  width: 100%;
  padding: 12px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
`;

export default ShipmentProfile; 