import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/colors';
import { useBillingInfo, useUpdateBilling, type BillingInfo } from '../../api/endpoints/billing';

interface BillingSectionProps {
  shipmentId?: string;
  onBillingChange: (billingData: BillingInfo) => void;
}

const BillingSection: React.FC<BillingSectionProps> = ({ shipmentId, onBillingChange }) => {
  const [billTo, setBillTo] = useState('');
  const [accountId, setAccountId] = useState('');
  const [invoiceEmails, setInvoiceEmails] = useState<string[]>([]);

  const { data: billingInfo, isLoading } = useBillingInfo(shipmentId!);
  const updateBillingMutation = useUpdateBilling();

  React.useEffect(() => {
    if (billingInfo) {
      setBillTo(billingInfo.billTo);
      setAccountId(billingInfo.accountId);
      setInvoiceEmails(billingInfo.invoiceEmails);
    }
  }, [billingInfo]);

  const handleEmailRemove = (indexToRemove: number) => {
    setInvoiceEmails(invoiceEmails.filter((_, index) => index !== indexToRemove));
  };

  const handleEmailAdd = (email: string) => {
    if (email && !invoiceEmails.includes(email)) {
      setInvoiceEmails([...invoiceEmails, email]);
    }
  };

  const handleSave = async () => {
    if (!shipmentId) return;

    try {
      const updatedBillingInfo: BillingInfo = {
        billTo,
        accountId,
        invoiceEmails
      };
      
      await updateBillingMutation.mutateAsync({
        shipmentId,
        data: updatedBillingInfo
      });
      onBillingChange(updatedBillingInfo);
    } catch (error) {
      console.error('Failed to save billing info:', error);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Section>
      <SectionTitle>Billing</SectionTitle>
      <BillingGrid>
        <BillingColumn>
          <Label>Bill to</Label>
          <SelectWrapper>
            <Select 
              value={billTo}
              onChange={(e) => setBillTo(e.target.value)}
            >
              <option value="3rd Party">3rd Party</option>
              <option value="Sender">Sender</option>
              <option value="Recipient">Recipient</option>
            </Select>
          </SelectWrapper>
          {billTo === '3rd Party' && (
            <Input
              type="text"
              placeholder="Enter 3rd Party Account ID"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
            />
          )}
        </BillingColumn>

        <BillingColumn>
          <Label>Invoice to</Label>
          <EmailContainer>
            {invoiceEmails.map((email, index) => (
              <EmailPill key={index}>
                {email}
                <RemoveButton onClick={() => handleEmailRemove(index)}>×</RemoveButton>
              </EmailPill>
            ))}
            <EmailInput
              type="email"
              placeholder="Add email address"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleEmailAdd((e.target as HTMLInputElement).value);
                  (e.target as HTMLInputElement).value = '';
                }
              }}
            />
          </EmailContainer>
        </BillingColumn>
      </BillingGrid>
    </Section>
  );
};

const Section = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text.primary};
  margin-bottom: 1.5rem;
`;

const BillingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const BillingColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: 0.5rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  appearance: none;
  background-color: white;
  font-size: 1rem;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const SelectIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  &::placeholder {
    color: ${colors.text.placeholder};
  }
`;

const EmailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  min-height: 3rem;
`;

const EmailPill = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background-color: #F0F0F0;
  border-radius: 1rem;
  font-size: 0.875rem;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${colors.text.secondary};
  cursor: pointer;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1;
  
  &:hover {
    color: ${colors.text.primary};
  }
`;

const EmailInput = styled.input`
  border: none;
  padding: 0.5rem;
  flex: 1;
  min-width: 200px;
  
  &:focus {
    outline: none;
  }
`;

export default BillingSection; 