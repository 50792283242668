import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import NextButton from  '../../components/common/Button/NextButton';
import { Subtitle } from '../../components/common/Typography';
import { useKits, Kit } from '../../hooks/useKits';
import { supabase } from '../../api/supabase';
  
type SampleType = 'blood' | 'saliva' | 'urine' | 'cfDNA' | 'other';

interface DeviceKit {
  name: string;
  kitManufacturer: string;
  type: SampleType | '';
  hasIoT: string;
  iotManufacturer?: string;
  batteryLife?: string;
  maxCycles?: string;
}

const DeviceCollection: React.FC = () => {
  const navigate = useNavigate();
  const { trialId } = useParams();
  const { data: existingKits, isLoading, isError } = useKits();
  const [suggestions, setSuggestions] = useState<Kit[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: number]: boolean }>({});
  const [deviceKits, setDeviceKits] = useState<DeviceKit[]>([{
    name: '',
    kitManufacturer: '',
    type: '',
    hasIoT: '',
    iotManufacturer: '',
    batteryLife: '',
    maxCycles: ''
  }]);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/login');
        return;
      }

      // Verify we have valid trial progress
      const progress = localStorage.getItem('trialProgress');
      if (!progress) {
        navigate('/create-trial/setup');
        return;
      }

      const { trialId, hasTrialId } = JSON.parse(progress);
      if (!hasTrialId || !trialId) {
        navigate('/create-trial/setup');
        return;
      }
    };

    checkAuth();
  }, [navigate]);

  if (isError) {
    console.error('Failed to fetch kits');
    return <div>Error loading kits. Please try again later.</div>;
  }

  const handleNameInput = (index: number, value: string) => {
    handleInputChange(index, 'name', value);
    if (validationErrors[index]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[index];
        return newErrors;
      });
    }
    
    if (value.length > 0 && existingKits) {
      const matchingKits = Array.isArray(existingKits) 
        ? existingKits.filter((kit: Kit) => 
            kit.kit_name.toLowerCase().includes(value.toLowerCase())
          )
        : [];
      setSuggestions(matchingKits);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSelectKit = (index: number, kit: Kit) => {
    const newDeviceKits = [...deviceKits];
    newDeviceKits[index] = {
      name: kit.kit_name,
      kitManufacturer: kit.device_id,
      type: '',
      hasIoT: kit.iot_device ? 'yes' : 'no',
      iotManufacturer: kit.iot_device?.manufacturer || '',
      batteryLife: kit.iot_device?.battery_life || '',
      maxCycles: kit.iot_device?.cycle_count?.toString() || ''
    };
    setDeviceKits(newDeviceKits);
    setShowSuggestions(false);
  };

  const handleInputChange = (index: number, field: keyof DeviceKit, value: string) => {
    const newDeviceKits = [...deviceKits];
    newDeviceKits[index] = {
      ...newDeviceKits[index],
      [field]: value
    };
    setDeviceKits(newDeviceKits);
  };

  const handleAddKit = () => {
    setDeviceKits([...deviceKits, {
      name: '',
      kitManufacturer: '',
      type: '',
      hasIoT: '',
      iotManufacturer: '',
      batteryLife: '',
      maxCycles: ''
    }]);
  };

  const handleDeleteKit = (indexToDelete: number) => {
    setDeviceKits(deviceKits.filter((_, index) => index !== indexToDelete));
  };

  const handleNext = async () => {
    // Validate all kits have names
    const errors: { [key: number]: boolean } = {};
    let hasErrors = false;
    
    deviceKits.forEach((kit, index) => {
      if (!kit.name.trim()) {
        errors[index] = true;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setValidationErrors(errors);
      return;
    }

    try {
      const progress = JSON.parse(localStorage.getItem('trialProgress') || '{}');
      
      // Transform devices to ensure IoT is set and sample type is included
      const transformedDevices = deviceKits.map(kit => ({
        name: kit.name,
        kitManufacturer: kit.kitManufacturer,
        type: kit.type || 'other',  // Ensure type is set
        hasIoT: kit.hasIoT === 'yes',  // Convert to boolean, default to false
        iotManufacturer: kit.hasIoT === 'yes' ? kit.iotManufacturer : undefined,
        batteryLife: kit.hasIoT === 'yes' ? kit.batteryLife : undefined,
        maxCycles: kit.hasIoT === 'yes' ? kit.maxCycles : undefined
      }));
      
      localStorage.setItem('trialProgress', JSON.stringify({
        ...progress,
        hasDevices: true,
        devices: transformedDevices
      }));

      navigate(`/create-trial/shipments/${progress.trialId}`);
    } catch (err) {
      console.error('Failed to save devices:', err);
    }
  };

  return (
    <PageLayout>
      <Subtitle>What types of collection devices do you use?</Subtitle>
      {isLoading ? (
        <div>Loading kits...</div>
      ) : (
        deviceKits.map((kit, index) => (
          <KitSection key={index}>
            <KitHeader>
              <KitLabel>Collection Device</KitLabel>
              {index > 0 && (
                <DeleteButton onClick={() => handleDeleteKit(index)}>
                  ✕
                </DeleteButton>
              )}
            </KitHeader>
            <DiagnosticInputGroup>
              <InputWrapper>
                <StyledInput
                  placeholder="Name*"
                  value={kit.name}
                  onChange={(e) => handleNameInput(index, e.target.value)}
                  required
                  hasError={validationErrors[index]}
                />
                {validationErrors[index] && (
                  <ValidationError>Name is required</ValidationError>
                )}
              </InputWrapper>
              {showSuggestions && suggestions.length > 0 && (
                <SuggestionsContainer>
                  {suggestions.map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.kit_id}
                      onClick={() => handleSelectKit(index, suggestion)}
                    >
                      {suggestion.kit_name}
                    </SuggestionItem>
                  ))}
                </SuggestionsContainer>
              )}
              <Input
                placeholder="Manufacturer"
                value={kit.kitManufacturer}
                onChange={(e) => handleInputChange(index, 'kitManufacturer', e.target.value)}
              />
              <Select
                value={kit.type}
                onChange={(e) => handleInputChange(index, 'type', e.target.value as SampleType)}
              >
                <option value="">Select Sample Type</option>
                <option value="blood">Blood</option>
                <option value="saliva">Saliva</option>
                <option value="urine">Urine</option>
                <option value="cfDNA">cfDNA</option>
                <option value="other">Other</option>
              </Select>
              <Select
                value={kit.hasIoT}
                onChange={(e) => handleInputChange(index, 'hasIoT', e.target.value)}
              >
                <option value="">IoT Device*</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
            </DiagnosticInputGroup>

            {kit.hasIoT === 'yes' && (
              <IoTDeviceSection>
                <KitLabel>IoT Device</KitLabel>
                <IoTInputGroup>
                  <Input
                    placeholder="Manufacturer"
                    value={kit.iotManufacturer}
                    onChange={(e) => handleInputChange(index, 'iotManufacturer', e.target.value)}
                  />
                  <Input
                    placeholder="Battery Life"
                    value={kit.batteryLife}
                    onChange={(e) => handleInputChange(index, 'batteryLife', e.target.value)}
                  />
                  <Input
                    placeholder="Max Cycles"
                    value={kit.maxCycles}
                    onChange={(e) => handleInputChange(index, 'maxCycles', e.target.value)}
                  />
                </IoTInputGroup>
              </IoTDeviceSection>
            )}
          </KitSection>
        ))
      )}

      <AddKitButton onClick={handleAddKit}>
        + Include Another Device
      </AddKitButton>

      <NextButton onClick={handleNext}>
        Next
      </NextButton>
    </PageLayout>
  );
};

const KitSection = styled.div`
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #E0E0E0;

  &:last-child {
    border-bottom: none;
  }
`;

const KitRow = styled.div`
  margin-bottom: 24px;
`;

const KitLabel = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
`;

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const Input = styled.input<{ hasError?: boolean }>`
  padding: 12px;
  border: 1px solid ${props => props.hasError ? '#ff0000' : '#E0E0E0'};
  border-radius: 4px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: ${props => props.hasError ? '#ff0000' : '#000'};
  }

  &::placeholder {
    color: #666;
  }
`;

const StyledInput = styled(Input)``;

const Select = styled.select`
  padding: 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  background: white;

  &:focus {
    border-color: #000;
  }
`;

const AddKitButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 32px 0;

  &:hover {
    color: #000;
  }
`;

const KitHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: #ff0000;
  }
`;

const DiagnosticInputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 16px;

  > div {
    position: relative;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const IoTDeviceSection = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  padding-left: 16px;
  border-left: 2px solid #E0E0E0;
`;

const IoTInputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 16px;
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const SuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  
  &:hover {
    background-color: #F5F5F5;
  }
`;

const ValidationError = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default DeviceCollection; 