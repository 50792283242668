import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import ShipmentLabelRow from '../../components/Shipment/ShipmentLabelRow';
import { ShipmentService } from '../../api/endpoints/shipments';

const PackagesConfirmed: React.FC = () => {
  const navigate = useNavigate();

  const handleLabelClick = async (shipmentId: string) => {
    try {
      const labelBlob = await ShipmentService.getLabel(shipmentId);
      const url = window.URL.createObjectURL(labelBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `shipping-label-${shipmentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading label:', error);
    }
  };

  return (
    <PageLayout>
      <Container>
        <Title>Packages Confirmed</Title>
        
        <SectionContainer>
          <SectionTitle>Outbound Package</SectionTitle>
          <ShipmentLabelRow
            shipmentId="outbound-1"
            quantity="1"
            contents="Box and Timestrip v0.2"
            service="FedEx Express Saver"
            onLabelClick={() => handleLabelClick('outbound-1')}
          />
        </SectionContainer>

        <SectionContainer>
          <SectionTitle>Return Packages</SectionTitle>
          {[1, 2, 3].map((_, index) => (
            <ShipmentLabelRow
              key={index}
              shipmentId={`inbound-${index + 1}`}
              quantity="1"
              contents="Box and Timestrip v0.2"
              service="FedEx Priority Overnight"
              onLabelClick={() => handleLabelClick(`inbound-${index + 1}`)}
            />
          ))}
        </SectionContainer>

        <ButtonContainer>
          <Button onClick={() => navigate('/send-kits')}>Send More Kits</Button>
          <Button onClick={() => navigate('/shipment-manager')}>
            Go to Shipment Manager
          </Button>
        </ButtonContainer>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

export default PackagesConfirmed; 