import React from 'react';
import styled from '@emotion/styled';
import Header from 'components/common/Header/Header';
import { Title } from 'components/common/Typography';

interface PageLayoutProps {
  children: React.ReactNode;
  title?: string;
  isLandingPage?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, title, isLandingPage = false }) => {
  return (
    <PageContainer>
      <Header />
      <MainContent>
        {title && (
          <StyledTitle isLandingPage={isLandingPage}>
            {title}
          </StyledTitle>
        )}
        {children}
      </MainContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
  background: white;
`;

const MainContent = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const StyledTitle = styled(Title)<{ isLandingPage: boolean }>`
  font-size: ${props => props.isLandingPage ? '32px' : '24px'};
  margin-bottom: ${props => props.isLandingPage ? '16px' : '24px'};
  font-weight: ${props => props.isLandingPage ? '600' : '500'};
`;

export default PageLayout; 