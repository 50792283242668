import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import odysseyLogo from '../../assets/odyssey.png';
import odysseyIcon from '../../assets/odyssey-icon.png';
import { ENDPOINTS } from '../../api/config';
import api from '../../api/client';
import { supabase } from '../../api/supabase';

interface LoginFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: ''
  });
  const [error, setError] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    try {
      console.log('Attempting login with:', formData);  // Debug log
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });
      
      if (error) {
        console.error('Supabase auth error:', error);  // Debug log
        throw error;
      }
      
      console.log('Login successful:', data);  // Debug log
      navigate('/dashboard');
      
    } catch (err) {
      console.error('Login error:', err);  // Debug log
      setError('Login failed. Please try again.');
    }
  };

  return (
    <LoginContainer>
      <TopLogoContainer>
        <IconImage src={odysseyIcon} alt="" />
        <LogoImage src={odysseyLogo} alt="Odyssey" />
      </TopLogoContainer>
      
      <ContentContainer>
        <Title>Login</Title>
        
        <LoginForm onSubmit={handleSubmit}>
          <InputField
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <InputField
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />

          {error && <ErrorMessage>{error}</ErrorMessage>}
          
          <LoginButton type="submit">
            Login
          </LoginButton>
        </LoginForm>
      </ContentContainer>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
`;

const TopLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 120px;
`;

const IconImage = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
`;

const LogoImage = styled.img`
  height: 24px;
  width: auto;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast; // For sharper text rendering
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
`;

const LoginForm = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #000;
  }

  &::placeholder {
    color: #000;
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  text-align: center;
  font-size: 14px;
`;

const LoginButton = styled.button`
  width: 100%;
  max-width: 200px;  // Make button smaller
  padding: 12px 24px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 16px auto 0;  // Center the button with auto margins
  display: block;

  &:hover {
    background-color: #333;
  }
`;

export default Login; 