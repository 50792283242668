import { useQuery } from '@tanstack/react-query';
import api from '../api/client';
import { ENDPOINTS } from '../api/config';
import { ApiResponse } from '../types/api';

export interface Kit {
  kit_id: string;
  kit_name: string;
  device_id: string;
  iot_device?: {
    device_id: string;
    manufacturer: string;
    battery_life: string;
    cycle_count: number;
    exists: boolean;
  };
}

export const useKits = () => {
  return useQuery<Kit[], Error>(
    ['kits'], 
    async (): Promise<Kit[]> => {
      try {
        const response = await api.get<Kit[]>(ENDPOINTS.kits.base);
        return response.data;
      } catch (error) {
        console.error('Error fetching kits:', error);
        return []; // Return empty array instead of throwing to prevent redirect
      }
    },
    {
      retry: false,
      useErrorBoundary: false,
      onError: () => []
    }
  );
}; 