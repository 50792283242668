import React from 'react';
import styled from '@emotion/styled';
import { useShipmentProfiles } from '../../api/endpoints/shipments';
import type { ShipmentProfile } from '../../types/models/shipment';

const colors = {
  primary: '#007AFF',
  border: '#E0E0E0',
  text: {
    primary: '#333333',
    secondary: '#666666',
    placeholder: '#999999'
  }
} as const;

interface ShipmentHeaderProps {
  trialId?: string;
}

const ShipmentHeader: React.FC<ShipmentHeaderProps> = ({ trialId = "__-__-__-__" }) => {
  const { data: profiles, isLoading } = useShipmentProfiles();

  return (
    <>
      <ShipmentTypeSelect disabled>
        <option value="outbound">Outbound Shipment</option>
      </ShipmentTypeSelect>

      <SelectionRow>
        <SelectGroup>
          <SelectLabel>Select Shipment Profile</SelectLabel>
          <StyledSelect disabled>
            {isLoading ? (
              <option>Loading profiles...</option>
            ) : (
              profiles?.data.map((profile: ShipmentProfile) => (
                <option key={profile.id} value={profile.id}>
                  {profile.name}
                </option>
              ))
            )}
          </StyledSelect>
        </SelectGroup>

        <SelectGroup>
          <SelectLabel>Trial ID</SelectLabel>
          <StyledSelect disabled>
            <option value="trial-1">{trialId}</option>
          </StyledSelect>
        </SelectGroup>
      </SelectionRow>
    </>
  );
};

const ShipmentTypeSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  background-color: white;
  margin-bottom: 2rem;
  cursor: not-allowed;
  opacity: 0.7;
`;

const SelectionRow = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const SelectLabel = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  font-weight: 500;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export default ShipmentHeader;