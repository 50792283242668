import { useQuery, useMutation } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse } from '../../types/api';

export interface BillingInfo {
  billTo: string;
  accountId: string;
  invoiceEmails: string[];
}

// API functions
const billingApi = {
  getBillingInfo: async (shipmentId: string): Promise<ApiResponse<BillingInfo>> => {
    return api.get(ENDPOINTS.billing.info(shipmentId));
  },

  updateBilling: async (shipmentId: string, billingInfo: BillingInfo): Promise<ApiResponse<void>> => {
    return api.put(ENDPOINTS.billing.update(shipmentId), billingInfo);
  }
};

// React Query hooks
export const useBillingInfo = (shipmentId: string) => {
  return useQuery(
    ['billing', shipmentId],
    () => billingApi.getBillingInfo(shipmentId),
    {
      enabled: !!shipmentId,
      select: (response) => response.data
    }
  );
};

export const useUpdateBilling = () => {
  return useMutation({
    mutationFn: ({ shipmentId, data }: { shipmentId: string; data: BillingInfo }) =>
      billingApi.updateBilling(shipmentId, data)
  });
};

// Service export
export const billingService = {
  ...billingApi
}; 