import React, { useState } from 'react';
import styled from '@emotion/styled';

interface SelectProps {
  placeholder: string;
  options?: Array<{
    value: string;
    label: string;
  }>;
  onChange?: (value: string) => void;
  value?: string;
}

const Select: React.FC<SelectProps> = ({
  placeholder,
  options = [],
  onChange,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (newValue: string) => {
    onChange?.(newValue);
    setIsOpen(false);
  };

  return (
    <SelectContainer>
      <SelectButton
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <span>{value || placeholder}</span>
        <ChevronIcon isOpen={isOpen}>▼</ChevronIcon>
      </SelectButton>

      {isOpen && (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              key={option.value}
              onClick={() => handleSelect(option.value)}
              isSelected={value === option.value}
            >
              {option.label}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  min-width: 160px;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #999;
  }
`;

const ChevronIcon = styled.span<{ isOpen: boolean }>`
  transition: transform 0.3s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const OptionsContainer = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Option = styled.li<{ isSelected: boolean }>`
  padding: 8px 16px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#f0f0f0' : 'transparent')};
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`; 

export default Select;