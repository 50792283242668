import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { Title, Label, Subtitle } from '../../components/common/Typography';
//import { Search } from '@/components/common/Icons';
import NextButton from '../../components/common/Button/NextButton';
import { useNavigate } from 'react-router-dom';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';
import { useCreateShipment, useShipmentProfiles } from '../../api/endpoints/shipments';
import { useTrials } from '../../api/endpoints/trials';
import { ShipmentStatus } from '../../types/models/shipment';
import type { BasicShipment } from '../../types/models/shipment';
import { useDebounce } from '../../hooks/useDebounce';
import type { Trial } from '../../types/models/trial';
import type { Address } from '../../types/models/address';
import { useAddresses } from '../../api/endpoints/addresses';



const colors = {
    primary: '#007AFF',
    border: '#E0E0E0',
    text: {
      primary: '#333333',
      secondary: '#666666',
      placeholder: '#999999'
    }
  } as const;

const spacing = {
  xs: '0.5rem',    // 8px
  sm: '1rem',      // 16px
  md: '1.5rem',    // 24px
  lg: '2rem',      // 32px
  xl: '2.5rem',    // 40px
} as const;

interface CollectionDevice {
  id: string;
  quantity: number;
}

interface Shipment {
  id?: string;
  address: Address;
  collectionDevices: CollectionDevice[];
  template: string;
  status?: ShipmentStatus;
  createdAt?: string;
  updatedAt?: string;
}

interface ShipmentData extends Partial<Shipment> {
  address: Address;
  collectionDevices: CollectionDevice[];
  template: string;
}

const ShipmentSetup: React.FC = () => {
  const navigate = useNavigate();
  const { data: addresses, isLoading: addressesLoading } = useAddresses();
  const { data: profiles, isLoading: profilesLoading } = useShipmentProfiles();
  const { data: trialsList, isLoading: trialsLoading } = useTrials();
  const createShipmentMutation = useCreateShipment();
  const [selectedShipmentType, setSelectedShipmentType] = useState('');
  const [selectedProfile, setSelectedProfile] = useState('');
  const [selectedTrial, setSelectedTrial] = useState('');
  const [emailAddresses, setEmailAddresses] = useState<string[]>(['']);
  const [testRows, setTestRows] = useState([1, 2]);
  const [collectionDevices, setCollectionDevices] = useState<CollectionDevice[]>([
    { id: '', quantity: 1 }
  ]);
  const [formErrors, setFormErrors] = useState({
    address: false,
    collectionDevices: false,
    template: false
  });
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 300);

  const shipmentTypes = [
    { id: 'outbound', name: 'Outbound Shipment' }
  ];

  const shipmentProfiles = [
    { id: 'ibd-outbound', name: 'IBD Outbound' }
  ];

  const addTestRow = () => {
    setTestRows([...testRows, testRows.length + 1]);
  };

  const removeTestRow = (index: number) => {
    if (testRows.length > 1) {
      setTestRows(testRows.filter((_, i) => i !== index));
    }
  };

  const addCollectionDevice = () => {
    setCollectionDevices([...collectionDevices, { id: '', quantity: 1 }]);
  };

  const removeCollectionDevice = (index: number) => {
    setCollectionDevices(collectionDevices.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    console.log('Submit button clicked');
    try {
      console.log('Current state:', {
        selectedAddress,
        collectionDevices,
        selectedProfile,
        selectedTrial
      });

      // For demo purposes - skip API call and just navigate
      console.log('Skipping API call for demo');
      const mockResponse = {
        id: 'mock-shipment-123',
        status: ShipmentStatus.DRAFT,
        // ... other mock data if needed for the next view
      };
      
      localStorage.setItem('shipmentSetup', JSON.stringify(mockResponse));
      console.log('Navigating to create-labels');
      navigate('/send-kits/create-labels');

      /* Comment out actual API call for now
      if (!selectedAddress) {
        throw new Error('Address is required');
      }

      const shipmentData: BasicShipment = {
        address: selectedAddress,
        collectionDevices: collectionDevices,
        template: selectedProfile,
        status: ShipmentStatus.DRAFT
      };

      const response = await createShipmentMutation.mutateAsync(shipmentData);
      localStorage.setItem('shipmentSetup', JSON.stringify(response));
      navigate('/send-kits/create-labels');
      */
      
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    }
  };

  const handleAddressSelect = (address: Address) => {
    setSelectedAddress(address);
  };

  const filteredAddresses = useMemo(() => {
    if (!addresses?.data || !debouncedSearch) return [];
    return addresses.data.filter((address: Address) => 
      address.street.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
      address.city.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  }, [addresses, debouncedSearch]);

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader />

        <Section>
          <StyledSubtitle>Deliver To</StyledSubtitle>
          <SearchBar>
            <SearchInput 
              type="text" 
              placeholder="Search Address Book"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {debouncedSearch && filteredAddresses.length > 0 && (
              <AddressDropdown>
                {filteredAddresses.map((address: Address) => (
                  <AddressOption 
                    key={address.id}
                    onClick={() => handleAddressSelect(address)}
                  >
                    <div>{address.street}</div>
                    <div>{address.city}, {address.state}</div>
                  </AddressOption>
                ))}
              </AddressDropdown>
            )}
          </SearchBar>

          {selectedAddress && (
            <SelectedAddress>
              <p>{selectedAddress.street}</p>
              <p>{`${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.postal_code}`}</p>
              <p>{selectedAddress.country}</p>
            </SelectedAddress>
          )}

          <DeliveryForm>
            <FormColumns>
              <ContactColumn>
                <SectionHeader>Contact Details</SectionHeader>
                <FormGroup>
                  <Input type="text" placeholder="Contact Name" />
                </FormGroup>
                <FormGroup>
                  <Input type="text" placeholder="Company" />
                </FormGroup>
                <FormGroup>
                  <Input type="text" placeholder="Tax ID/EORI Number" />
                </FormGroup>
                <FormGroup>
                  <Input type="tel" placeholder="Phone Number" />
                </FormGroup>
                <FormGroup>
                  <Input type="tel" placeholder="Phone Extension" />
                </FormGroup>
                <FormGroup>
                  <Input type="email" placeholder="Email" />
                </FormGroup>
              </ContactColumn>

              <AddressColumn>
                <SectionHeader>Address</SectionHeader>
                <FormGroup>
                  <Input type="text" placeholder="Country/Territory" />
                </FormGroup>
                <FormGroup>
                  <Input type="text" placeholder="Address Line 1" />
                </FormGroup>
                <FormGroup>
                  <Input type="text" placeholder="Address Line 2" />
                </FormGroup>
                <ZipCityRow>
                  <FormGroup>
                    <Input type="text" placeholder="Zip" />
                  </FormGroup>
                  <FormGroup>
                    <Input type="text" placeholder="City" />
                  </FormGroup>
                </ZipCityRow>
                <FormGroup>
                  <Input type="text" placeholder="State/District" />
                </FormGroup>
                <AddressTypeGroup>
                  <CheckboxContainer>
                    <CheckboxGroup>
                      <StyledCheckbox type="checkbox" id="residential" />
                      <CheckboxLabel htmlFor="residential">Residential</CheckboxLabel>
                    </CheckboxGroup>
                    
                    <CheckboxGroup>
                      <StyledCheckbox type="checkbox" id="clinic" />
                      <CheckboxLabel htmlFor="clinic">Clinic/Hospital</CheckboxLabel>
                    </CheckboxGroup>
                  </CheckboxContainer>

                  <CheckboxGroup>
                    <StyledCheckbox type="checkbox" id="other" />
                    <CheckboxLabel htmlFor="other">Other</CheckboxLabel>
                    <DescriptionInput type="text" placeholder="Description" />
                  </CheckboxGroup>
                </AddressTypeGroup>
              </AddressColumn>
            </FormColumns>

            <OptionsRow>
              <OptionGroup>
                <CheckboxContainer>
                  <StyledCheckbox type="checkbox" id="saveAddress" />
                  <Label htmlFor="saveAddress">Save New Address in Recipient Books</Label>
                </CheckboxContainer>
                <InputContainer>
                  <StyledInput type="text" placeholder="Address ID" />
                </InputContainer>
              </OptionGroup>

              <OptionGroup>
                <CheckboxContainer>
                  <StyledCheckbox type="checkbox" id="emailNotif" />
                  <Label htmlFor="emailNotif">Email Notifications</Label>
                </CheckboxContainer>
                {emailAddresses.map((email, index) => (
                  <InputContainer key={index}>
                    <StyledInput 
                      type="email" 
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => {
                        const newEmails = [...emailAddresses];
                        newEmails[index] = e.target.value;
                        setEmailAddresses(newEmails);
                      }}
                    />
                    <ButtonContainer>
                      {emailAddresses.length > 1 && (
                        <CircleButton 
                          onClick={() => {
                            const newEmails = emailAddresses.filter((_, i) => i !== index);
                            setEmailAddresses(newEmails);
                          }}
                        >
                          ×
                        </CircleButton>
                      )}
                      {index === emailAddresses.length - 1 && (
                        <CircleButton 
                          onClick={() => setEmailAddresses([...emailAddresses, ''])}
                        >
                          +
                        </CircleButton>
                      )}
                    </ButtonContainer>
                  </InputContainer>
                ))}
              </OptionGroup>
            </OptionsRow>
          </DeliveryForm>
        </Section>

        <Section>
          <Title>Shipment Information</Title>
          
          <FormSection>
            <Label>Template Shipment</Label>
            <StyledSelect defaultValue="">
              <option value="" disabled>Select Template</option>
              <option value="test">Test Shipment</option>
            </StyledSelect>
          </FormSection>

          <FormSection>
            <Label>Trial ID</Label>
            <StyledSelect
              value={selectedTrial}
              onChange={(e) => setSelectedTrial(e.target.value)}
              disabled={trialsLoading}
            >
              <option value="" disabled>Select Trial</option>
              {trialsList?.data?.results?.map((trial: Trial) => (
                <option key={trial.trial_id} value={trial.trial_id}>
                  {trial.trial_id}
                </option>
              ))}
            </StyledSelect>
          </FormSection>

          <FormSection>
            <Label>Collection Devices</Label>
            {collectionDevices.map((device, index) => (
              <DeviceRow key={index}>
                <DeviceSelect
                  value={device.id}
                  onChange={(e) => {
                    const newDevices = [...collectionDevices];
                    newDevices[index].id = e.target.value;
                    setCollectionDevices(newDevices);
                  }}
                >
                  <option value="">Select Device</option>
                  <option value="tasso-v02">Tasso v0.2</option>
                  {/* More device options */}
                </DeviceSelect>
                
                <QuantityInput
                  type="number"
                  min="1"
                  value={device.quantity}
                  onChange={(e) => {
                    const newDevices = [...collectionDevices];
                    newDevices[index].quantity = parseInt(e.target.value) || 1;
                    setCollectionDevices(newDevices);
                  }}
                />
                
                {collectionDevices.length > 1 && (
                  <RemoveDeviceButton onClick={() => removeCollectionDevice(index)}>
                    ×
                  </RemoveDeviceButton>
                )}
              </DeviceRow>
            ))}
            
            <AddDeviceButton onClick={addCollectionDevice}>
              + Add Collection Device
            </AddDeviceButton>
          </FormSection>

          <Section>
            <Title>Physical Product Information</Title>
            <FormSection>
              <Label>Collection Devices/Test IDs</Label>
              {/* ... rest of the test IDs section ... */}
            </FormSection>
          </Section>
        </Section>

        <Section>
          <CheckboxContainer>
            <StyledCheckbox type="checkbox" id="saveTemplate" />
            <Label htmlFor="saveTemplate">Save New Kit Template</Label>
          </CheckboxContainer>
          <TemplateInput 
            type="text" 
            placeholder="Template ID"
            style={{ marginLeft: '36px', marginTop: '12px', width: '300px' }}
          />
        </Section>

        <NextButton 
          onClick={() => {
            console.log('Next button clicked');
            handleSubmit();
          }}
          style={{ marginTop: '20px' }}
        >
          Next
        </NextButton>
        {formErrors.address && (
          <ErrorMessage>Please select a delivery address</ErrorMessage>
        )}
        {formErrors.collectionDevices && (
          <ErrorMessage>Please add at least one collection device</ErrorMessage>
        )}
        {formErrors.template && (
          <ErrorMessage>Please select a template</ErrorMessage>
        )}
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 50rem;  // 800px
  margin: 0 auto;
  padding: ${spacing.md};
`;

const Section = styled.div`
  margin-bottom: ${spacing.xl};

  &:not(:first-of-type) {
    margin-top: ${spacing.xl};
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.md};
`;

const SelectGroup = styled.div`
  margin-bottom: ${spacing.md};
`;

const FormGroup = styled.div`
  margin-bottom: ${spacing.sm};
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
  padding-right: 32px;
  background-position: right 12px center;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: ${spacing.sm};
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const DeliveryForm = styled.div`
  margin-top: ${spacing.sm};
`;

const ProductForm = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${spacing.md};
`;

const SearchBar = styled.div`
  position: relative;
  margin-bottom: ${spacing.sm};
`;

const SearchInput = styled(Input)`
  padding-right: 40px;
`;

// const SearchIcon = styled(Search)`
//   position: absolute;
//   right: 12px;
//   top: 50%;
//   transform: translateY(-50%);
//   color: #999;
// `;

const FormColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.md};
  margin-top: ${spacing.sm};
`;

const ContactColumn = styled.div``;

const AddressColumn = styled.div``;

const ZipCityRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${spacing.sm};
`;

const AddressTypeGroup = styled.div`
  margin-top: ${spacing.sm};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  margin-bottom: ${spacing.sm};
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;

const CheckboxLabel = styled.label`
  color: #666;
  font-size: 16px;
  cursor: pointer;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: 0;

  &:checked {
    background-color: #007AFF;
    border-color: #007AFF;
  }

  &:checked::after {
    content: '';
    position: absolute;
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const DescriptionInput = styled(Input)`
  width: 200px;
  margin-left: 8px;
`;

const AddButton = styled.button`
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: #007AFF;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    background: #0056b3;
  }
`;

const OptionsRow = styled.div`
  margin-top: 24px;
  border-top: 1px solid #E0E0E0;
  padding-top: 24px;
  display: flex;
  gap: 48px;
`;

const OptionGroup = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  position: relative;
  margin-left: 36px;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  color: #333;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: -80px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 8px;
`;

const CircleButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: #007AFF;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    background: #0056b3;
  }

  &:first-child {
    font-size: 24px;
  }
`;

const DimensionsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 48px;
  align-items: flex-start;
`;

const DimensionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

const DimensionInput = styled.input`
  width: 60px;
  height: 40px;
  padding: 8px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  text-align: center;
`;

const MultiplierText = styled.span`
  color: #666;
  font-size: 16px;
`;

const UnitText = styled.span`
  color: #666;
  font-size: 16px;
  margin-left: 8px;
`;

const WeightGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const WeightInput = styled.input`
  width: 100px;
  height: 40px;
  padding: 8px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
  margin-left: ${spacing.md};
  margin-top: ${spacing.sm};
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${spacing.xl};
  align-items: flex-start;
`;

const QuantityInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
`;

const FormSection = styled.div`
  margin-bottom: ${spacing.lg};
`;

const TestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
  margin-top: ${spacing.sm};
`;

const TestRow = styled.div`
  display: grid;
  grid-template-columns: 40px 2fr 1fr 2fr 2fr;
  gap: ${spacing.md};
  align-items: flex-end;
  position: relative;
`;

const RowNumber = styled.div`
  color: ${colors.text.secondary};
  font-size: 1rem;
  padding-bottom: ${spacing.sm};
`;

const BatchInput = styled(StyledInput)`
  width: 100%;
`;

const AddKitButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  color: ${colors.primary};
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: ${spacing.xs} 0;
  margin-top: ${spacing.xs};
`;

const AddIcon = styled.span`
  font-size: 20px;
  line-height: 1;
`;

const TestIdLabel = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TempLoggerSelect = styled(StyledSelect)`
  min-width: 200px;
`;

const RemoveButton = styled.button`
  position: absolute;
  right: -40px;
  top: 55%;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: #007AFF;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    background: #0056b3;
  }
`;

const TemplateInput = styled(StyledInput)`
  width: 18.75rem;  // 300px
  margin-left: ${spacing.xl};
  margin-top: ${spacing.sm};
`;

const BatchLabel = styled(Label)`
  white-space: nowrap;
`;

const BaseSelectStyles = `
  padding: ${spacing.sm};
  border: 0.125rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;
  color: ${colors.text.primary};
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right ${spacing.sm} center;
  background-size: 1rem;
  cursor: pointer;
`;


const ShipmentTypeSelect = styled.select`
  ${BaseSelectStyles}
  width: 100%;
  margin-bottom: 32px;
`;

const SelectionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.sm};
  margin-bottom: ${spacing.lg};
`;

const SelectLabel = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  font-weight: 500;
`;

const DeviceRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr auto;
  gap: ${spacing.sm};
  align-items: center;
  margin-bottom: ${spacing.sm};
`;

const DeviceSelect = styled(StyledSelect)`
  // Existing select styles
`;

const AddDeviceButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.primary};
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    opacity: 0.8;
  }
`;

const RemoveDeviceButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: ${colors.primary};
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: ${spacing.xs};
`;

const SectionHeader = styled(Subtitle)`
  font-size: 1rem;
  margin-bottom: ${spacing.xs};
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.875rem;
  margin-top: ${spacing.xs};
`;

const SelectedAddress = styled.div`
  padding: ${spacing.sm};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  margin-top: ${spacing.sm};
  
  p {
    margin: 0;
    line-height: 1.5;
  }
`;

const AddressDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const AddressOption = styled.div`
  padding: ${spacing.sm};
  cursor: pointer;
  
  &:hover {
    background: #f5f5f5;
  }
  
  div:first-of-type {
    font-weight: 500;
  }
  
  div:last-of-type {
    font-size: 0.875rem;
    color: ${colors.text.secondary};
  }
`;

// const NextButton = styled.button`
//   // ... existing styles ...
//   onClick: () => {
//     console.log('Next button clicked');
//     handleSubmit();
//   }
// `;

export default ShipmentSetup;
