import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/breakpoints';

interface ShipmentSummaryCardProps {
  type: 'outbound' | 'inbound';
  date: string;
  quantity: string;
  trialId: string;
  address: {
    name: string;
    street: string;
    cityStateZip: string;
  };
  service: {
    name: string;
    time: string;
  };
  contents: {
    name: string;
    quantity: number;
  }[];
  estimatedCost: string;
  onEdit?: () => void;
}

const ShipmentSummaryCard: React.FC<ShipmentSummaryCardProps> = ({
  type,
  date,
  quantity,
  trialId,
  address,
  service,
  contents,
  estimatedCost,
  onEdit
}) => {
  return (
    <Card>
      <Header>
        <LeftSection>
          <Quantity>{quantity}</Quantity>
          <Date>{date}</Date>
        </LeftSection>
        <RightSection>
          <TrialId>Trial ID: {trialId}</TrialId>
          <EditButton onClick={onEdit}>Edit</EditButton>
        </RightSection>
      </Header>

      <Content>
        <AddressSection>
          <SectionTitle>{type === 'outbound' ? 'Deliver to:' : 'Return to:'}</SectionTitle>
          <AddressText>
            <strong>{address.name}</strong>
            <div>{address.street}</div>
            <div>{address.cityStateZip}</div>
          </AddressText>
        </AddressSection>

        <ServiceSection>
          <SectionTitle>Service Level: {service.name}</SectionTitle>
          <ServiceTime>{service.time}</ServiceTime>
        </ServiceSection>

        <ContentsSection>
          <SectionTitle>{`${quantity} Box and Timestrip v0.2`}</SectionTitle>
          {contents.map((item, index) => (
            <ContentItem key={index}>
              • {item.name} [{item.quantity}]
            </ContentItem>
          ))}
        </ContentsSection>

        <CostSection>
          <SectionTitle>Estimated Invoice</SectionTitle>
          <Cost>{estimatedCost}</Cost>
        </CostSection>
      </Content>
    </Card>
  );
};

const Card = styled.div`
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid ${colors.border};
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Quantity = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${colors.text.primary};
`;

const Date = styled.div`
  color: ${colors.text.secondary};
`;

const TrialId = styled.div`
  color: ${colors.text.secondary};
`;

const EditButton = styled.button`
  padding: 0.5rem 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.25rem;
  background: none;
  color: ${colors.primary};
  cursor: pointer;
  
  &:hover {
    background: ${colors.primary};
    color: white;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const SectionTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${colors.text.primary};
`;

const AddressSection = styled.div``;

const AddressText = styled.div`
  color: ${colors.text.secondary};
  line-height: 1.5;
`;

const ServiceSection = styled.div``;

const ServiceTime = styled.div`
  color: ${colors.text.secondary};
`;

const ContentsSection = styled.div``;

const ContentItem = styled.div`
  color: ${colors.text.secondary};
  margin-bottom: 0.25rem;
`;

const CostSection = styled.div`
  text-align: right;
`;

const Cost = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${colors.text.primary};
`;

export default ShipmentSummaryCard; 