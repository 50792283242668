import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse } from '../../types/api';
import type { Address } from '../../types/models/address';

interface AddressFilters {
  user_id?: string;
  is_default?: boolean;
  label?: string;
}

export const addressApi = {
  // Basic CRUD
  getAddresses: async (params?: AddressFilters): Promise<ApiResponse<PaginatedApiResponse<Address>>> => {
    return api.get(ENDPOINTS.addresses.base, { params });
  },

  getAddress: async (id: string): Promise<ApiResponse<Address>> => {
    return api.get(ENDPOINTS.addresses.byId(id));
  },

  createAddress: async (data: Partial<Address>): Promise<ApiResponse<Address>> => {
    return api.post(ENDPOINTS.addresses.base, data);
  },

  updateAddress: async (id: string, data: Partial<Address>): Promise<ApiResponse<Address>> => {
    return api.put(ENDPOINTS.addresses.byId(id), data);
  },

  deleteAddress: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.addresses.byId(id));
  },

  // Special operations
  setDefault: async (id: string): Promise<ApiResponse<Address>> => {
    return api.post(`${ENDPOINTS.addresses.byId(id)}/set-default`);
  },

  validateAddress: async (data: Partial<Address>): Promise<ApiResponse<Address>> => {
    return api.post(`${ENDPOINTS.addresses.base}/validate`, data);
  }
};

// React Query hooks
export const useAddresses = () => {
  return useQuery<ApiResponse<Address[]>>(['addresses'], async () => {
    const response = await api.get('/addresses/');
    return response.data;
  });
};

export const useAddress = (id: string) => {
  return useQuery(
    ['address', id], 
    () => addressApi.getAddress(id),
    { enabled: !!id }
  );
};

export const useCreateAddress = () => {
  return useMutation(addressApi.createAddress);
};

export const useUpdateAddress = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<Address> }) => 
      addressApi.updateAddress(id, data)
  );
};

export const useDeleteAddress = () => {
  return useMutation(addressApi.deleteAddress);
};

export const useValidateAddress = () => {
  return useMutation(addressApi.validateAddress);
}; 