export const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000/api',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
};

export const ENDPOINTS = {
  auth: {
    login: '/auth/login/',
    refresh: '/auth/refresh/',
    logout: '/auth/logout/',
    me: '/auth/me/',
    profile: '/auth/profile/',
    changePassword: '/auth/change-password',
    requestReset: '/auth/request-reset',
    resetPassword: '/auth/reset-password'
  },
  addresses: {
    base: '/addresses',
    byId: (id: string) => `/addresses/${id}`
  },
  contactPersons: {
    base: '/contact-persons',
    byId: (id: string) => `/contact-persons/${id}`
  },
  kits: {
    base: '/kits',
    byId: (id: string) => `/kits/${id}`,
    templates: '/kits/templates'
  },
  parcels: {
    base: '/parcels',
    byId: (id: string) => `/parcels/${id}`,
    templates: '/parcels/templates'
  },
  patients: {
    base: '/patients',
    byId: (id: string) => `/patients/${id}`
  },
  shipments: {
    base: '/shipments',
    byId: (id: string) => `/shipments/${id}`,
    label: (id: string) => `/shipments/${id}/label`,
    profiles: '/shipments/profiles',
    templates: '/shipments/templates',
    toggleStar: (id: string) => `/shipments/${id}/toggle-star`
  },
  sites: {
    base: '/sites',
    byId: (id: string) => `/sites/${id}`,
    inventory: (id: string) => `/sites/${id}/inventory`
  },
  trials: {
    base: '/trials/',
    byId: (id: string) => `/trials/${id}/`,
    summary: (id: string) => `/trials/${id}/summary/`,
    devices: (id: string) => `/trials/${id}/devices/`,
    shipments: (id: string) => `/trials/${id}/shipments/`,
    sites: (id: string) => `/trials/${id}/sites/`,
    upload: (id: string) => `/trials/${id}/upload/`,
    check: (id: string) => `/trials/check/${id}/`
  },
  billing: {
    info: (id: string) => `/billing/${id}`,
    update: (id: string) => `/billing/${id}`
  },
  devices: {
    base: '/devices',
    byId: (id: string) => `/devices/${id}`,
    iot: '/devices/iot',
    iotById: (id: string) => `/devices/iot/${id}`,
    templates: '/devices/templates'
  }
};