import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import NextButton from '../../components/common/Button/NextButton';
import { Subtitle } from '../../components/common/Typography';
import worldMap from '../../assets/worldmap.png';
import { useCreateTrial } from '../../api/endpoints/trials';
import type { 
  Trial, 
  CollectionDevice, 
  StandardShipment, 
  ShipmentContent, 
  ShipmentDimensions,
  SampleType 
} from '../../types/models/trial';
import type { ApiError } from '../../types/api';
import { supabase } from '../../api/supabase';

interface TrialData {
  trial_id: string;
  description: string;
  patient_count: number;
  site_count: number;
  collection_devices: CollectionDevice[];
  standard_shipments: StandardShipment[];
}

const formatDimensions = (dimensions: ShipmentDimensions): string => {
  return `${dimensions.length} × ${dimensions.width} × ${dimensions.height} in`;
};

const getSampleTypeIcon = (type: SampleType): string => {
  switch (type) {
    case 'blood':
      return '🩸';
    case 'saliva':
      return '💧';
    case 'urine':
      return '🧪';
    case 'cfDNA':
      return '🧬';
    default:
      return '📦';
  }
};

const formatSampleType = (type: SampleType): string => {
  return type.charAt(0).toUpperCase() + type.slice(1);
};

const Confirmation: React.FC = () => {
  const navigate = useNavigate();
  const { trialId } = useParams<{ trialId: string }>();
  const createMutation = useCreateTrial();
  const [description, setDescription] = useState('');
  const [localData, setLocalData] = useState<TrialData | null>(null);
  
  useEffect(() => {
    // Get data from localStorage as fallback
    const progress = localStorage.getItem('trialProgress');
    if (progress) {
      try {
        const parsedData = JSON.parse(progress);
        console.log('Local storage data:', parsedData);
        
        // Transform devices to include sample type
        const devices = parsedData.devices || [];
        const deviceMap = devices.reduce((acc: Record<string, any>, device: any) => {
          acc[device.kitManufacturer] = device;
          return acc;
        }, {});
        
        // Transform shipments to include sample type from devices
        const shipments = parsedData.shipments?.templates || [];
        const transformedShipments = shipments.map((shipment: any) => ({
          ...shipment,
          contents: (shipment.contents || []).map((content: any) => ({
            ...content,
            sample_type: deviceMap[content.kit_id]?.type || 'other'
          }))
        }));
        
        // Construct proper trial data structure
        const trialData: TrialData = {
          trial_id: parsedData.trialId || '',
          description: parsedData.description || '',
          patient_count: parsedData.patient_count || 0,
          site_count: parsedData.site_count || 0,
          collection_devices: devices.map((device: any) => ({
            name: device.name,
            hasIoT: device.hasIoT,
            type: device.type || 'other'
          })),
          standard_shipments: transformedShipments
        };
        
        setLocalData(trialData);
      } catch (err) {
        console.error('Error parsing localStorage data:', err);
      }
    }
  }, []);

  const handleSubmit = async () => {
    if (!localData) return;
    
    try {
      const dataToSubmit = {
        trial_id: localData.trial_id,
        description: description || '',
        collection_devices: localData.collection_devices || [],
        standard_shipments: localData.standard_shipments || [],
        patient_count: localData.patient_count || 0,
        site_count: localData.site_count || 0,
        status: 'draft'
      };

      console.log('Creating new trial:', dataToSubmit);
      await createMutation.mutateAsync(dataToSubmit);
      
      localStorage.removeItem('trialProgress');
      navigate('/dashboard', { replace: true });
    } catch (err) {
      console.error('Error creating trial:', err);
    }
  };

  // Show loading state if no data
  if (!localData) return <div>No data available</div>;

  return (
    <PageLayout>
      <Subtitle>Confirmation</Subtitle>

      <ContentContainer>
        <SummarySection>
          <SummaryTitle>Trial Summary</SummaryTitle>
          
          <SummaryRow>
            <Label>Trial ID</Label>
            <Value>#{localData.trial_id}</Value>
          </SummaryRow>

          <SummaryRow>
            <Label>Description:</Label>
            <DescriptionInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter trial description..."
            />
          </SummaryRow>

          <SummaryRow>
            <Label>Patient Count:</Label>
            <Value>{localData.patient_count || 0}</Value>
          </SummaryRow>

          <SummaryRow>
            <Label>Site Count:</Label>
            <Value>{localData.site_count || 0}</Value>
          </SummaryRow>

          <SectionRow>
            <SectionLabel>Collection Devices</SectionLabel>
            <DevicesContainer>
              {(localData.collection_devices || []).map((device: CollectionDevice, index: number) => (
                <DeviceItem key={index}>
                  {device.name} {device.hasIoT === true && '⚡'}
                </DeviceItem>
              ))}
            </DevicesContainer>
          </SectionRow>

          <SectionRow>
            <SectionLabel>Standard Shipments</SectionLabel>
            <ShipmentGrid>
              {(localData.standard_shipments || []).map((shipment: StandardShipment, index: number) => (
                <ShipmentBox key={index}>
                  <ShipmentHeader>
                    <ShipmentDimensions>
                      {formatDimensions(shipment.dimensions)}
                    </ShipmentDimensions>
                    <ShipmentWeight>
                      {shipment.weight} lbs
                    </ShipmentWeight>
                  </ShipmentHeader>
                  <ShipmentContents>
                    <ContentsLabel>Contains:</ContentsLabel>
                    {(shipment.contents || []).map((content: ShipmentContent, idx: number) => (
                      <ContentItem key={idx}>
                        <ContentMain>
                          <ContentQuantity>{content.quantity}x</ContentQuantity>
                          <ContentName>{content.kit_name}</ContentName>
                          {getSampleTypeIcon(content.sample_type)}
                        </ContentMain>
                        <SampleTypeTag>
                          {formatSampleType(content.sample_type)}
                        </SampleTypeTag>
                      </ContentItem>
                    ))}
                  </ShipmentContents>
                </ShipmentBox>
              ))}
            </ShipmentGrid>
          </SectionRow>
        </SummarySection>

        <MapSection>
          <WorldMap src={worldMap} alt="World Map" />
          <SitesList>
            <SitesTitle>Sites</SitesTitle>
            {/* Add sites list here when available */}
          </SitesList>
        </MapSection>
      </ContentContainer>

      <NextButton onClick={handleSubmit}>
        Create Trial
      </NextButton>
    </PageLayout>
  );
};

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 40px;
  margin: 40px auto;
  max-width: 1200px;
`;

const SummarySection = styled.div`
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 24px;
`;

const SummaryTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const SummaryRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
`;

const Label = styled.div`
  font-weight: 500;
  width: 120px;
  flex-shrink: 0;
`;

const Value = styled.div`
  flex: 1;
`;

const DescriptionInput = styled.textarea`
  flex: 1;
  min-height: 80px;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  resize: vertical;
`;

const SectionRow = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
`;

const SectionLabel = styled.div`
  font-weight: 500;
  width: 120px;
  flex-shrink: 0;
`;

const DevicesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const DeviceItem = styled.div`
  margin: 8px 0;
  padding: 8px;
  background: #F5F5F5;
  border-radius: 4px;
`;

const ShipmentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
`;

const ShipmentBox = styled.div`
  padding: 16px;
  background: #F5F5F5;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MapSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const WorldMap = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const SitesList = styled.div`
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 24px;
`;

const SitesTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const ShipmentHeader = styled.div`
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 12px;
`;

const ShipmentDimensions = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const ShipmentWeight = styled.div`
  color: #666;
`;

const ShipmentContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContentsLabel = styled.div`
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
`;

const ContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: white;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
`;

const ContentMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContentQuantity = styled.span`
  font-weight: 500;
`;

const ContentName = styled.span`
  color: #333;
`;

const SampleTypeTag = styled.span`
  font-size: 12px;
  color: #666;
  background: #F5F5F5;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: 500;
`;

export default Confirmation; 