import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/breakpoints';
import { ShipmentService } from '../../api/endpoints/shipments';

interface ShipmentLabelRowProps {
  shipmentId: string;
  quantity: string;
  contents: string;
  service: string;
  onLabelClick?: () => void;
}

const ShipmentLabelRow: React.FC<ShipmentLabelRowProps> = ({
  shipmentId,
  quantity,
  contents,
  service,
  onLabelClick,
}) => {
  const handleLabelClick = async () => {
    try {
      if (onLabelClick) {
        onLabelClick();
        return;
      }

      const labelBlob = await ShipmentService.getLabel(shipmentId);
      const url = window.URL.createObjectURL(labelBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `shipping-label-${shipmentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading label:', error);
    }
  };

  return (
    <Container>
      <ShipmentId>Shipment ID #{shipmentId}</ShipmentId>
      <Contents>{quantity} {contents}</Contents>
      <Service>{service}</Service>
      <LabelLink onClick={handleLabelClick}>Shipping Label</LabelLink>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr auto;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const ShipmentId = styled.div`
  font-weight: 500;
  color: ${colors.text.primary};
`;

const Contents = styled.div`
  color: ${colors.text.primary};
`;

const Service = styled.div`
  color: ${colors.text.secondary};
`;

const LabelLink = styled.button`
  color: ${colors.primary};
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-weight: 500;

  &:hover {
    color: ${colors.primary}dd;
  }
`;

export default ShipmentLabelRow; 