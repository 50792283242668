import React from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import worldMap from '../../assets/pickupworld.png';
import { usePickup } from '../../context/PickupContext';
import { useNavigate } from 'react-router-dom';

const PickupConfirmation: React.FC = () => {
  const { pickupData } = usePickup();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      // Here you would make your API call
      // await api.createPickup(pickupData);
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to submit pickup:', error);
    }
  };

  return (
    <PageLayout title="Confirmation">
      <Container>
        <ContentGrid>
          <DetailsCard>
            <ConfirmationTitle>Pickup Confirmation</ConfirmationTitle>
            
            <ConfirmationNumber>
              Confirmation # {pickupData.confirmationNumber}
            </ConfirmationNumber>
            
            <SiteId>Site ID #{pickupData.siteId}</SiteId>
            
            <AddressSection>
              <Label>Address:</Label>
              <AddressText>
                {pickupData.address.line1}
                {pickupData.address.line2 && <>, {pickupData.address.line2}</>},
                <br />
                {pickupData.address.zip}, {pickupData.address.city}, {pickupData.address.state}, {pickupData.address.country}
              </AddressText>
            </AddressSection>

            <TimeSection>
              <TimeRow>
                <Label>Total Packages</Label>
                <Value>{pickupData.totalPackages}</Value>
              </TimeRow>
              <TimeRow>
                <Label>Date</Label>
                <Value>{new Date(pickupData.pickupDate).toLocaleDateString()}</Value>
              </TimeRow>
              <TimeRow>
                <Label>Earliest Time</Label>
                <Value>{pickupData.earliestTime}</Value>
              </TimeRow>
              <TimeRow>
                <Label>Latest Time</Label>
                <Value>{pickupData.latestTime}</Value>
              </TimeRow>

              <InstructionsSection>
                {pickupData.locationInstructions && (
                  <InstructionsRow>
                    <Label>Pickup Instructions:</Label>
                    <InstructionsText>{pickupData.locationInstructions}</InstructionsText>
                  </InstructionsRow>
                )}
                
                {pickupData.message && (
                  <InstructionsRow>
                    <Label>Message:</Label>
                    <InstructionsText>{pickupData.message}</InstructionsText>
                  </InstructionsRow>
                )}
              </InstructionsSection>
            </TimeSection>
          </DetailsCard>

          <MapSection>
            <MapImage src={worldMap} alt="World Map with Location" />
          </MapSection>
        </ContentGrid>

        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DetailsCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ConfirmationTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const ConfirmationNumber = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
`;

const SiteId = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
`;

const AddressSection = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.span`
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
`;

const AddressText = styled.p`
  line-height: 1.5;
`;

const TimeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TimeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Value = styled.span`
  font-weight: 500;
`;

const MapSection = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MapImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #000;
  }
`;

const InstructionsSection = styled.div`
  margin-top: 24px;
  border-top: 1px solid #E0E0E0;
  padding-top: 24px;
`;

const InstructionsRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const InstructionsText = styled.p`
  margin-top: 8px;
  line-height: 1.5;
  white-space: pre-wrap;
  color: #333;
`;

export default PickupConfirmation; 