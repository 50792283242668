import React, { createContext, useContext, useState } from 'react';
import { PickupData } from '../types/models/pickup';

interface PickupContextType {
  pickupData: PickupData;
  updatePickupData: (data: Partial<PickupData>) => void;
  generateConfirmation: () => void;
}

const PickupContext = createContext<PickupContextType | undefined>(undefined);

export const PickupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [pickupData, setPickupData] = useState<PickupData>({
    accountNo: '',
    contactName: '',
    shipmentType: '',
    phoneNumber: '',
    address: {
      country: '',
      line1: '',
      line2: '',
      zip: '',
      city: '',
      state: ''
    },
    totalPackages: 0,
    pickupDate: '',
    earliestTime: '',
    latestTime: ''
  });

  const updatePickupData = (data: Partial<PickupData>) => {
    setPickupData(prev => ({ ...prev, ...data }));
  };

  const generateConfirmation = () => {
    // Generate random confirmation number and site ID
    const confirmationNumber = `ADBWU${Math.floor(1000 + Math.random() * 9000)}`;
    const siteId = `AB${Math.floor(1000 + Math.random() * 9000)}`;
    
    updatePickupData({ confirmationNumber, siteId });
  };

  return (
    <PickupContext.Provider value={{ pickupData, updatePickupData, generateConfirmation }}>
      {children}
    </PickupContext.Provider>
  );
};

export const usePickup = () => {
  const context = useContext(PickupContext);
  if (context === undefined) {
    throw new Error('usePickup must be used within a PickupProvider');
  }
  return context;
}; 