import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import RouteGuard from './components/RouteGuard';
import Login from './components/Login/Login';
import WorkflowSelection from './pages/WorkflowSelection';
import TrialSetup from './pages/CreateTrial/TrialSetup';
import DeviceCollection from './pages/CreateTrial/DeviceCollection';
import OutboundShipments from './pages/CreateTrial/OutboundShipments';
import Confirmation from './pages/CreateTrial/Confirmation';
import ShipmentSetup from './pages/SendOutKits/ShipmentSetup';
import CreateLabels from './pages/SendOutKits/CreateLabels';
import ConfirmationAndBilling from './pages/SendOutKits/ConfirmationAndBilling';
import PackagesConfirmed from './pages/SendOutKits/PackagesConfirmed';
import ShipmentManager from './pages/ManageInventory/ShipmentManager';
import ShipmentProfile from './pages/ManageInventory/ShipmentProfile';
import SchedulePickupForm from './pages/SchedulePickup/SchedulePickupForm';
import PickupDetails from './pages/SchedulePickup/PickupDetails';
import PickupConfirmation from './pages/SchedulePickup/PickupConfirmation';
import { PickupProvider } from './context/PickupContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: true,
    },
  },
});

const App: React.FC = () => {
  const getTrialProgress = () => {
    try {
      const progress = localStorage.getItem('trialProgress');
      const parsedProgress = progress ? JSON.parse(progress) : {};
      return parsedProgress;
    } catch (err) {
      console.error('Error getting trial progress:', err);
      return {};
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <PickupProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
            
            <Route 
              path="/dashboard" 
              element={
                <RouteGuard redirectTo="/login">
                  <WorkflowSelection />
                </RouteGuard>
              } 
            />
            
            <Route path="/create-trial">
              <Route 
                index 
                element={<Navigate to="/create-trial/setup" replace />} 
              />
              <Route 
                path="setup" 
                element={
                  <RouteGuard redirectTo="/login">
                    <TrialSetup />
                  </RouteGuard>
                } 
              />
              <Route 
                path="devices/:trialId" 
                element={
                  <RouteGuard redirectTo="/create-trial/setup">
                    <DeviceCollection />
                  </RouteGuard>
                } 
              />
              <Route 
                path="shipments/:trialId" 
                element={
                  <RouteGuard redirectTo="/create-trial/devices">
                    <OutboundShipments />
                  </RouteGuard>
                } 
              />
              <Route 
                path="confirmation/:trialId" 
                element={
                  <RouteGuard redirectTo="/create-trial/shipments">
                    <Confirmation />
                  </RouteGuard>
                } 
              />
            </Route>

            <Route path="/send-kits">
              <Route 
                index
                element={
                  <RouteGuard redirectTo="/login">
                    <ShipmentSetup />
                  </RouteGuard>
                } 
              />
              <Route 
                path="confirmation"
                element={
                  <RouteGuard redirectTo="/send-kits">
                    <ConfirmationAndBilling />
                  </RouteGuard>
                } 
              />
              <Route 
                path="create-labels"
                element={
                  <RouteGuard redirectTo="/send-kits">
                    <CreateLabels />
                  </RouteGuard>
                } 
              />
              <Route 
                path="confirmed"
                element={
                  <RouteGuard redirectTo="/send-kits">
                    <PackagesConfirmed />
                  </RouteGuard>
                } 
              />
            </Route>

            <Route path="/shipment-manager">
              <Route 
                index
                element={
                  <RouteGuard redirectTo="/login">
                    <ShipmentManager />
                  </RouteGuard>
                } 
              />
              <Route 
                path=":id" 
                element={
                  <RouteGuard redirectTo="/login">
                    <ShipmentProfile />
                  </RouteGuard>
                } 
              />
            </Route>

            <Route path="/schedule-pickup">
              <Route 
                index
                element={
                  <RouteGuard redirectTo="/login">
                    <SchedulePickupForm />
                  </RouteGuard>
                } 
              />
              <Route 
                path="details"
                element={
                  <RouteGuard redirectTo="/schedule-pickup">
                    <PickupDetails />
                  </RouteGuard>
                } 
              />
              <Route 
                path="confirmation"
                element={
                  <RouteGuard redirectTo="/schedule-pickup/details">
                    <PickupConfirmation />
                  </RouteGuard>
                } 
              />
            </Route>

            <Route 
              path="*" 
              element={
                localStorage.getItem('isLoggedIn') === 'true'
                  ? <Navigate to="/dashboard" replace /> 
                  : <Navigate to="/login" replace />
              } 
            />
          </Routes>
        </Router>
      </PickupProvider>
    </QueryClientProvider>
  );
};

export default App; 