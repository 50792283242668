import styled from '@emotion/styled';

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 48px;
  color: #000;
`;

export const Label = styled.label`
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
`; 