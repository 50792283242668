import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse } from '../../types/api';
import type { 
  Shipment, 
  CreateShipmentDTO, 
  UpdateShipmentDTO, 
  ShipmentStatus,
  ShipmentProfile,
  BasicShipment 
} from '../../types/models/shipment';

interface ShipmentFilters {
  trial_id?: string;
  status?: ShipmentStatus;
  carrier?: string;
  date_from?: string;
  date_to?: string;
}

interface ShipmentResponse {
  inboundData: Shipment[];
  outboundData: Shipment[];
}

export const shipmentApi = {
  getShipments: async (params?: ShipmentFilters): Promise<ApiResponse<ShipmentResponse>> => {
    return api.get(ENDPOINTS.shipments.base, { params });
  },

  getShipment: async (id: string): Promise<ApiResponse<Shipment>> => {
    return api.get(ENDPOINTS.shipments.byId(id));
  },

  createShipment: async (data: CreateShipmentDTO | BasicShipment): Promise<ApiResponse<Shipment>> => {
    return api.post(ENDPOINTS.shipments.base, data);
  },

  updateShipment: async (id: string, data: UpdateShipmentDTO): Promise<ApiResponse<Shipment>> => {
    return api.put(ENDPOINTS.shipments.byId(id), data);
  },

  deleteShipment: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.shipments.byId(id));
  },

  // Profile operations
  getProfiles: async (): Promise<ApiResponse<ShipmentProfile[]>> => {
    return api.get(ENDPOINTS.shipments.profiles);
  },

  // Label operations
  getLabel: async (id: string): Promise<Blob> => {
    const response = await api.get(ENDPOINTS.shipments.label(id), {
      responseType: 'blob'
    });
    return response.data;
  },

  // Additional operations
  toggleStar: async (id: string): Promise<ApiResponse<void>> => {
    return api.post(ENDPOINTS.shipments.toggleStar(id));
  }
};

// React Query hooks
export const useShipments = (params?: ShipmentFilters) => {
  return useQuery(['shipments', params], () => shipmentApi.getShipments(params));
};

export const useShipment = (id: string) => {
  return useQuery(
    ['shipment', id], 
    () => shipmentApi.getShipment(id),
    { enabled: !!id }
  );
};

export const useCreateShipment = () => {
  return useMutation(shipmentApi.createShipment);
};

export const useUpdateShipment = () => {
  return useMutation(
    ({ id, data }: { id: string; data: UpdateShipmentDTO }) => 
      shipmentApi.updateShipment(id, data)
  );
};

export const useDeleteShipment = () => {
  return useMutation(shipmentApi.deleteShipment);
};

export const useShipmentProfiles = () => {
  return useQuery(['shipmentProfiles'], shipmentApi.getProfiles);
};

export const useShipmentLabel = (id: string) => {
  return useQuery(
    ['shipment', id, 'label'], 
    () => shipmentApi.getLabel(id),
    { enabled: !!id }
  );
};

export const useToggleShipmentStar = () => {
  return useMutation(shipmentApi.toggleStar);
};

// For backward compatibility
export const ShipmentService = {
  getShipmentById: async (id: string): Promise<Shipment> => {
    const response = await shipmentApi.getShipment(id);
    return response.data;
  },

  getShipments: async (params?: ShipmentFilters) => {
    const response = await shipmentApi.getShipments(params);
    return response.data;
  },

  getLabel: async (id: string): Promise<Blob> => {
    return shipmentApi.getLabel(id);
  },

  toggleStar: async (id: string): Promise<void> => {
    const response = await shipmentApi.toggleStar(id);
    return response.data;
  },

  createOutbound: async (data: Partial<Shipment>) => {
    const response = await api.post('/shipments/create_outbound/', data);
    return response.data;
  },

  createReturnLabel: async (shipmentId: string) => {
    const response = await api.post(`/shipments/${shipmentId}/create_return_label/`);
    return response.data;
  },

  getProfiles: async () => {
    const response = await api.get('/shipment-profiles/');
    return response.data;
  }
}; 