import type { Address } from './address';
import type { Parcel } from './parcel';
import type { Trial } from './trial';
import type { User } from './user';

export enum ShipmentStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED'
}

export interface ShipmentContent {
  kit_id: string;
  kit_name: string;
  quantity: number;
  sample_type: 'blood' | 'saliva' | 'urine' | 'cfDNA' | 'other';
}

export enum ServiceLevel {
  GROUND = 'GROUND',
  EXPRESS = 'EXPRESS',
  PRIORITY = 'PRIORITY'
}

// Base shipment interface matching Django model
export interface Shipment {
  id: string;
  tracking_number: string;
  trial: Trial;
  origin: Address;
  destination: Address;
  status: ShipmentStatus;
  carrier: string;
  service_type: string;
  cost: number;
  created_at: string;
  pickup_date?: string;
  expected_delivery?: string;
  actual_delivery?: string;
  requires_signature: boolean;
  hold_at_location: boolean;
  sample_ids?: string[];
  notification_emails?: string[];
  failures?: string[];
  parcels: Parcel[];
  created_by?: User;
  contents?: ShipmentContent[];
}

// DTO for creating new shipments
export interface CreateShipmentDTO {
  trial_id: string;
  origin_id: string;
  destination_id: string;
  carrier: string;
  service_type: string;
  requires_signature?: boolean;
  hold_at_location?: boolean;
  notification_emails?: string[];
  parcel_ids?: string[];
  status?: ShipmentStatus;
}

// DTO for updating existing shipments
export interface UpdateShipmentDTO extends Partial<Omit<Shipment, 'id' | 'created_at' | 'created_by'>> {
  status?: ShipmentStatus;
}

// For shipment profiles/templates
export interface ShipmentProfile {
  id: string;
  name: string;
  dimensions?: {
    length: number;
    width: number;
    height: number;
  };
  weight?: number;
  contents?: ShipmentContent[];
  service_level?: ServiceLevel;
}

// For basic shipment creation in SendOutKits flow
export interface BasicShipment {
  id?: string;
  address: Address;
  collectionDevices: Array<{
    id: string;
    quantity: number;
  }>;
  template: string;
  status?: ShipmentStatus;
  created_at?: string;
  updated_at?: string;
}