import React, { useState } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { useNavigate } from 'react-router-dom';
import { usePickup } from '../../context/PickupContext';

const SchedulePickupForm: React.FC = () => {
  const navigate = useNavigate();
  const { updatePickupData } = usePickup();
  
  const [formData, setFormData] = useState({
    accountNo: '',
    contactName: '',
    shipmentType: '',
    phoneNumber: '',
    extension: '',
    address: {
      country: '',
      line1: '',
      line2: '',
      zip: '',
      city: '',
      state: ''
    },
    message: ''
  });

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digits
    const digits = value.replace(/\D/g, '');
    
    // Format as XXX-XXX-XXXX
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    if (formatted.length <= 12) {
      setFormData(prev => ({
        ...prev,
        phoneNumber: formatted
      }));
    }
  };

  const handleExtensionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers for extension
    const value = e.target.value.replace(/\D/g, '');
    setFormData(prev => ({
      ...prev,
      extension: value
    }));
  };

  const isPhoneValid = () => {
    const digitsOnly = formData.phoneNumber.replace(/\D/g, '');
    return digitsOnly.length === 10;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!isPhoneValid()) {
      alert('Please enter a valid phone number');
      return;
    }

    updatePickupData({
      accountNo: formData.accountNo,
      contactName: formData.contactName,
      shipmentType: formData.shipmentType,
      phoneNumber: formData.phoneNumber,
      extension: formData.extension,
      address: formData.address,
      message: formData.message
    });
    
    navigate('/schedule-pickup/details');
  };

  return (
    <PageLayout title="Schedule Pickups">
      <FormContainer onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Account No.</Label>
          <Input 
            type="text" 
            name="accountNo"
            value={formData.accountNo}
            onChange={handleChange}
            placeholder="Value" 
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Contact Name</Label>
          <Input 
            type="text" 
            name="contactName"
            value={formData.contactName}
            onChange={handleChange}
            placeholder="Value" 
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Shipment Type</Label>
          <Select>
            <option value="">Value</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Pickup Address</Label>
          <Select>
            <option value="">Saved Addresses</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Phone no.</Label>
          <PhoneGroup>
            <PhoneInput 
              type="tel"
              placeholder="___-___-____"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              pattern="\d{3}-\d{3}-\d{4}"
              required
            />
            <ExtLabel>ext.</ExtLabel>
            <ExtInput 
              type="text"
              value={formData.extension}
              onChange={handleExtensionChange}
              maxLength={6}
            />
          </PhoneGroup>
          {formData.phoneNumber && !isPhoneValid() && (
            <ErrorMessage>Please enter a valid 10-digit phone number</ErrorMessage>
          )}
        </FormGroup>

        <AddressSection>
          <Label>Address</Label>
          <Input 
            type="text" 
            name="address.country"
            value={formData.address.country}
            onChange={handleChange}
            placeholder="Country/Territory" 
            required
          />
          <Input 
            type="text" 
            name="address.line1"
            value={formData.address.line1}
            onChange={handleChange}
            placeholder="Address Line 1" 
            required
          />
          <Input 
            type="text" 
            name="address.line2"
            value={formData.address.line2}
            onChange={handleChange}
            placeholder="Address Line 2" 
          />
          <AddressGrid>
            <Input 
              type="text" 
              name="address.zip"
              value={formData.address.zip}
              onChange={handleChange}
              placeholder="Zip" 
              required
            />
            <Input 
              type="text" 
              name="address.city"
              value={formData.address.city}
              onChange={handleChange}
              placeholder="City" 
              required
            />
            <Input 
              type="text" 
              name="address.state"
              value={formData.address.state}
              onChange={handleChange}
              placeholder="State" 
              required
            />
          </AddressGrid>
        </AddressSection>

        <FormGroup>
          <Label>Message</Label>
          <TextArea 
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Value" 
          />
        </FormGroup>

        <SubmitButton>Submit</SubmitButton>
      </FormContainer>
    </PageLayout>
  );
};

const FormContainer = styled.form`
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  background: white;
`;

const PhoneGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const PhoneInput = styled(Input)`
  width: 200px;
`;

const ExtLabel = styled.span`
  color: #666;
`;

const ExtInput = styled(Input)`
  width: 100px;
`;

const AddressSection = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 12px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #000;
  }
`;

const ErrorMessage = styled.span`
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

export default SchedulePickupForm; 