import styled from '@emotion/styled';

const NextButton = styled.button`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 12px 40px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #000;
  }

  &:disabled {
    background-color: #E0E0E0;
    cursor: not-allowed;
  }
`;

export default NextButton; 