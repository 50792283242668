import React, { useState } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { breakpoints, colors } from '../../components/constants/breakpoints';
import NextButton from '../../components/common/Button/NextButton';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';
import { useNavigate } from 'react-router-dom';

const CreateLabels: React.FC = () => {
  const navigate = useNavigate();
  const [createReturn, setCreateReturn] = useState(false);

  const handleReturnCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateReturn(e.target.checked);
  };

  const handleNext = () => {
    navigate('/send-kits/confirmation');
  };

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader />

        <ServiceSection>
          <SectionTitle>Select Outbound Service</SectionTitle>
          <ServiceHeader>
            <DropoffSelect>
              <option>Schedule Dropoff</option>
            </DropoffSelect>
            <LocationInput placeholder="Find FedEx Nearby" />
            <TimeInput placeholder="Earliest Pickup Time" />
            <TimeInput placeholder="Latest Pickup Time" />
          </ServiceHeader>

          <ServiceOptions>
            <ServiceCard>
              <ServiceName>FedEx First Overnight</ServiceName>
              <ServiceDetails>
                <DeliveryTime>Delivers by 9:30am NBD</DeliveryTime>
                <Price>$00.00</Price>
              </ServiceDetails>
            </ServiceCard>
            
            <ServiceCard>
              <ServiceName>FedEx Priority Overnight</ServiceName>
              <ServiceDetails>
                <DeliveryTime>Delivers by 10:30am NBD</DeliveryTime>
                <Price>$00.00</Price>
              </ServiceDetails>
            </ServiceCard>
            
            <ServiceCard>
              <ServiceName>FedEx Standard Overnight</ServiceName>
              <ServiceDetails>
                <DeliveryTime>Delivers by 3:00pm NBD</DeliveryTime>
                <Price>$00.00</Price>
              </ServiceDetails>
            </ServiceCard>
            
            <ServiceCard>
              <ServiceName>FedEx 2Day</ServiceName>
              <ServiceDetails>
                <DeliveryTime>Delivers by 4:30pm N(2)BD</DeliveryTime>
                <Price>$00.00</Price>
              </ServiceDetails>
            </ServiceCard>
            
            <ServiceCard>
              <ServiceName>FedEx 2Day AM</ServiceName>
              <ServiceDetails>
                <DeliveryTime>Delivers by 10:30am N(2)BD</DeliveryTime>
                <Price>$00.00</Price>
              </ServiceDetails>
            </ServiceCard>
            
            <ServiceCard>
              <ServiceName>FedEx Express Saver</ServiceName>
              <ServiceDetails>
                <DeliveryTime>Delivers by 4:30pm N(3)BD</DeliveryTime>
                <Price>$00.00</Price>
              </ServiceDetails>
            </ServiceCard>
          </ServiceOptions>

          <ShippingOptions>
            <CheckboxOption>
              <CheckboxRow>
                <Checkbox type="checkbox" id="signature" />
                <CheckboxLabel htmlFor="signature">Require Signature</CheckboxLabel>
              </CheckboxRow>
            </CheckboxOption>

            <CheckboxOption>
              <CheckboxRow>
                <Checkbox type="checkbox" id="holdLocation" />
                <CheckboxLabel htmlFor="holdLocation">Hold at Local FedEx Location</CheckboxLabel>
              </CheckboxRow>
              <BaseInput 
                placeholder="Enter ZIP to Select Location" 
                style={{ marginLeft: '1.75rem', width: '12.5rem' }}
              />
            </CheckboxOption>

            <CheckboxOption>
              <CheckboxRow>
                <Checkbox type="checkbox" id="emailReturn" />
                <CheckboxLabel htmlFor="emailReturn">Email Return Shipping Label</CheckboxLabel>
              </CheckboxRow>
              <BaseInput 
                type="email"
                placeholder="Email" 
                style={{ marginLeft: '1.75rem', width: '18.75rem' }}
              />
            </CheckboxOption>

            <CheckboxOption>
              <CheckboxRow>
                <Checkbox type="checkbox" id="references" />
                <CheckboxLabel htmlFor="references">Add Shipment References</CheckboxLabel>
              </CheckboxRow>
            </CheckboxOption>

            <CheckboxOption>
              <CheckboxRow>
                <Checkbox 
                  type="checkbox" 
                  id="return" 
                  checked={createReturn}
                  onChange={handleReturnCheckbox}
                />
                <CheckboxLabel htmlFor="return">Create a Return Label</CheckboxLabel>
              </CheckboxRow>
            </CheckboxOption>
          </ShippingOptions>
        </ServiceSection>

        {createReturn && (
          <ReturnSection>
            <SectionTitle>Create Corresponding Return Label(s)</SectionTitle>
            
            <ReturnAddressSection>
              <SubsectionTitle>Return Address</SubsectionTitle>
              
              <CheckboxOption>
                <Checkbox type="checkbox" id="returnToOrigin" />
                <CheckboxLabel htmlFor="returnToOrigin">Return to Origin Address</CheckboxLabel>
              </CheckboxOption>
              
              <SearchInput placeholder="Search Address Book" />
            </ReturnAddressSection>

            <ReturnDivider />

            <ReturnProfileSection>
              <SectionTitle>Return Shipment(s) Profile</SectionTitle>
              
              <CheckboxOption style={{ marginTop: '1rem' }}>
                <CheckboxRow>
                  <Checkbox type="checkbox" id="sameAsOutbound" />
                  <CheckboxLabel htmlFor="sameAsOutbound">Same as Outbound Shipment</CheckboxLabel>
                </CheckboxRow>
              </CheckboxOption>

              <ProfileDetailsGrid>
                <div>
                  <FormGroup>
                    <FormLabel>Saved Kit Template</FormLabel>
                    <StyledSelect>
                      <option>Saved SKUs</option>
                    </StyledSelect>
                  </FormGroup>

                  <FormGroup style={{ marginTop: '2rem' }}>
                    <FormLabel>Package Dimensions</FormLabel>
                    <DimensionsInputGroup>
                      <DimensionInput placeholder="L" />
                      <DimensionSeparator>×</DimensionSeparator>
                      <DimensionInput placeholder="W" />
                      <DimensionSeparator>×</DimensionSeparator>
                      <DimensionInput placeholder="H" />
                      <DimensionsUnit>(in inches)</DimensionsUnit>
                    </DimensionsInputGroup>
                  </FormGroup>
                </div>

                <div>
                  <FormGroup>
                    <FormLabel>Quantity</FormLabel>
                    <QuantityInput type="number" placeholder="1" />
                  </FormGroup>

                  <FormGroup style={{ marginTop: '2rem' }}>
                    <FormLabel>Weight</FormLabel>
                    <WeightInputGroup>
                      <WeightInput type="number" placeholder="0.0" />
                      <WeightUnit>lbs.</WeightUnit>
                    </WeightInputGroup>
                  </FormGroup>
                </div>
              </ProfileDetailsGrid>
            </ReturnProfileSection>

            <ReturnDivider />

            <ReturnServiceSection>
              <SubsectionTitle>Return Shipment Service</SubsectionTitle>
              
              <ServiceOptions>
                <ServiceCard>
                  <ServiceName>FedEx First Overnight</ServiceName>
                  <ServiceDetails>
                    <DeliveryTime>Delivers by 9:30am NBD</DeliveryTime>
                    <Price>$00.00*</Price>
                  </ServiceDetails>
                </ServiceCard>
                <ServiceCard>
                  <ServiceName>FedEx Priority Overnight</ServiceName>
                  <ServiceDetails>
                    <DeliveryTime>Delivers by 10:30am NBD</DeliveryTime>
                    <Price>$00.00*</Price>
                  </ServiceDetails>
                </ServiceCard>
                <ServiceCard>
                  <ServiceName>FedEx Standard Overnight</ServiceName>
                  <ServiceDetails>
                    <DeliveryTime>Delivers by 3:00pm NBD</DeliveryTime>
                    <Price>$00.00*</Price>
                  </ServiceDetails>
                </ServiceCard>
                <ServiceCard>
                  <ServiceName>FedEx 2Day</ServiceName>
                  <ServiceDetails>
                    <DeliveryTime>Delivers by 4:30pm N(2)BD</DeliveryTime>
                    <Price>$00.00*</Price>
                  </ServiceDetails>
                </ServiceCard>
                <ServiceCard>
                  <ServiceName>FedEx 2Day AM</ServiceName>
                  <ServiceDetails>
                    <DeliveryTime>Delivers by 10:30am N(2)BD</DeliveryTime>
                    <Price>$00.00*</Price>
                  </ServiceDetails>
                </ServiceCard>
                <ServiceCard>
                  <ServiceName>FedEx Express Saver</ServiceName>
                  <ServiceDetails>
                    <DeliveryTime>Delivers by 4:30pm N(3)BD</DeliveryTime>
                    <Price>$00.00*</Price>
                  </ServiceDetails>
                </ServiceCard>
              </ServiceOptions>

              <ShippingOptions>
                <CheckboxOption>
                  <Checkbox type="checkbox" id="returnSignature" />
                  <CheckboxLabel htmlFor="returnSignature">Require Signature</CheckboxLabel>
                </CheckboxOption>

                <CheckboxOption>
                  <Checkbox type="checkbox" id="returnHoldLocation" />
                  <CheckboxLabel htmlFor="returnHoldLocation">Hold at Local FedEx Location</CheckboxLabel>
                  <BaseInput 
                    placeholder="Enter ZIP to Select Location" 
                    style={{ marginLeft: '1.5rem', width: '12rem' }}
                  />
                </CheckboxOption>

                <CheckboxOption>
                  <Checkbox type="checkbox" id="returnEmailLabel" />
                  <CheckboxLabel htmlFor="returnEmailLabel">Email Return Shipping Label</CheckboxLabel>
                  <BaseInput 
                    placeholder="Email" 
                    style={{ marginLeft: '1.5rem', width: '11rem' }}
                  />
                </CheckboxOption>

                <CheckboxOption>
                  <Checkbox type="checkbox" id="returnReference" />
                  <CheckboxLabel htmlFor="returnReference">Add Shipment References</CheckboxLabel>
                  <BaseInput 
                placeholder="Shipment ID" 
                style={{ marginLeft: '1.75rem', width: '12.5rem' }}
                />
                <BaseInput 
                placeholder="P.O. No." 
                style={{ marginLeft: '1.75rem', width: '12.5rem' }}
                />
                <BaseInput 
                placeholder="Invoice No." 
                style={{ marginLeft: '1.75rem', width: '12.5rem' }}
                />
                <BaseInput 
                placeholder="Department No." 
                style={{ marginLeft: '1.75rem', width: '12.5rem' }}
                />
                </CheckboxOption>
              </ShippingOptions>
            </ReturnServiceSection>
          </ReturnSection>
        )}

        <NextButton onClick={handleNext}>Next</NextButton>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 2rem;
`;

const spacing = {
    xs: '0.5rem',   // 8px
    sm: '1rem',     // 16px
    md: '1.5rem',   // 24px
    lg: '2rem',     // 32px
    xl: '3rem'      // 48px
  };

  
const BaseSelectStyles = `
  padding: 12px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: #F5F5F5;
    cursor: not-allowed;
  }
`;

const ShipmentTypeSelect = styled.select`
  ${BaseSelectStyles}
  width: 100%;
  margin-bottom: 32px;
`;

const SelectionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 32px;
`;

const SelectGroup = styled.div`
  position: relative;
`;

const SelectLabel = styled.label`
  display: block;
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const ServiceSection = styled.div`
  margin-top: 32px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 24px;
`;

const SubsectionTitle = styled.h3`
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
`;

const ServiceHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
`;

const DropoffSelect = styled.select`
  ${BaseSelectStyles}
  width: 100%;
`;

// const BaseInput = styled.input`
//   padding: 12px;
//   border: 2px solid #E0E0E0;
//   border-radius: 8px;
//   font-size: 16px;
//   color: #333;
//   width: 100%;

//   &:focus {
//     outline: none;
//     border-color: #007AFF;
//   }

//   &::placeholder {
//     color: #999;
//   }
// `;

const BaseInput = styled.input`
  padding: 0.75rem 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;
  
  &::placeholder {
    color: ${colors.text.placeholder};
  }

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const LocationInput = styled(BaseInput)``;

const TimeInput = styled(BaseInput)``;

const ServiceOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacing.md};
  margin-top: ${spacing.lg};
`;

const ServiceCard = styled.div`
  padding: ${spacing.md};
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${colors.primary};
    background-color: rgba(0, 122, 255, 0.05);
  }
`;

const ServiceName = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
`;

const ServiceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
`;

const DeliveryTime = styled.span``;

const Price = styled.span`
  font-weight: 500;
`;

const ShippingOptions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: ${spacing.lg};
  flex-wrap: wrap;
`;

const CheckboxOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
  min-width: fit-content;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
`;

const Checkbox = styled.input`
  width: 1.25rem; // 20px equivalent
  height: 1.25rem;
  margin: 0;
`;

const CheckboxLabel = styled.label`
  font-size: 1rem;
  color: ${colors.text};
  margin-right: ${spacing.sm};
`;

const ZipInput = styled(BaseInput)`
  width: 12.5rem; // 200px equivalent
`;

const EmailInput = styled(BaseInput)`
  width: 18.75rem; // 300px equivalent
`;

const ReferenceInput = styled(BaseInput)`
  width: 12.5rem; // 200px equivalent
`;

const SearchInput = styled(BaseInput)`
  margin-top: 16px;
`;

const ReturnSection = styled.div`
  margin-top: 48px;
`;

const ReturnAddressSection = styled.div`
  margin-top: 24px;
`;

const ReturnProfileSection = styled.div`
  margin-top: 3rem;
`;

const ProfileDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 3rem;
  margin-top: 2rem;
`;

const DimensionsInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const DimensionInput = styled.input`
  width: 3.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  text-align: center;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const DimensionSeparator = styled.span`
  color: ${colors.text.secondary};
  font-size: 1rem;
`;

const DimensionsUnit = styled.span`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
  margin-left: 0.25rem;
`;

const QuantityInput = styled.input`
  width: 6rem;
  padding: 0.75rem;
  border: 0.0625rem solid #E0E0E0;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const WeightInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const WeightInput = styled.input`
  width: 6rem;
  padding: 0.75rem;
  border: 0.0625rem solid #E0E0E0;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const WeightUnit = styled.span`
  color: #666;
  font-size: 0.875rem;
`;

const ReturnDivider = styled.hr`
  border: none;
  border-top: 0.0625rem solid ${colors.border};
  margin: 3rem auto;
  width: 100%;
`;

const ReturnServiceSection = styled.div`
  margin-top: 3rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const FormLabel = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  font-weight: 500;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const ReferenceInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

// const BaseInput = styled.input`
//   padding: 0.75rem 1rem;
//   border: 0.0625rem solid ${colors.border};
//   border-radius: 0.5rem;
//   font-size: 1rem;
  
//   &::placeholder {
//     color: ${colors.text.placeholder};
//   }

//   &:focus {
//     outline: none;
//     border-color: ${colors.primary};
//   }
// `;

export default CreateLabels;