import axios from 'axios';
import { getEnvironmentConfig } from '../config/environment';

const env = process.env.NODE_ENV || 'development';
const { apiBaseUrl } = getEnvironmentConfig(env);

const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      config: error.config
    });
    return Promise.reject(error);
  }
);

export default api;