import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import NextButton from '../../components/common/Button/NextButton';
import { Subtitle } from '../../components/common/Typography';

interface ShipmentKit {
  id: string;
  name: string;
  quantity: string;
  type?: string;
}

interface ShipmentTemplate {
  dimensions: {
    length: string;
    width: string;
    height: string;
  };
  weight: string;
  kits: ShipmentKit[];
}

const OutboundShipments: React.FC = () => {
  const navigate = useNavigate();
  const { trialId } = useParams();
  const [hasStandardShipments, setHasStandardShipments] = useState<boolean | null>(null);
  const [deviceOptions, setDeviceOptions] = useState<Array<{ name: string; id: string; type: string }>>([]);
  const [shipmentTemplates, setShipmentTemplates] = useState<ShipmentTemplate[]>([{
    dimensions: { length: '', width: '', height: '' },
    weight: '',
    kits: [{ id: '', name: '', quantity: '' }]
  }]);

  useEffect(() => {
    const progress = localStorage.getItem('trialProgress');
    if (progress) {
      const { devices } = JSON.parse(progress);
      if (devices) {
        setDeviceOptions(devices.map((device: any) => ({
          name: device.name,
          id: device.kitManufacturer,
          type: device.type || 'other'
        })));
      }
    }
  }, []);

  const handleAddKit = (templateIndex: number) => {
    const newTemplates = [...shipmentTemplates];
    newTemplates[templateIndex].kits.push({ id: '', name: '', quantity: '' });
    setShipmentTemplates(newTemplates);
  };

  const handleRemoveKit = (templateIndex: number, kitIndex: number) => {
    const newTemplates = [...shipmentTemplates];
    newTemplates[templateIndex].kits = newTemplates[templateIndex].kits.filter((_, idx) => idx !== kitIndex);
    setShipmentTemplates(newTemplates);
  };

  const handleAddTemplate = () => {
    setShipmentTemplates([...shipmentTemplates, {
      dimensions: { length: '', width: '', height: '' },
      weight: '',
      kits: [{ id: '', name: '', quantity: '' }]
    }]);
  };

  const handleInputChange = (
    templateIndex: number,
    field: string,
    value: string,
    kitIndex?: number
  ) => {
    const newTemplates = [...shipmentTemplates];
    if (field.includes('dimensions.')) {
      const dimensionField = field.split('.')[1] as keyof typeof newTemplates[number]['dimensions'];
      newTemplates[templateIndex].dimensions[dimensionField] = value;
    } else if (kitIndex !== undefined) {
      if (field === 'id') {
        const selectedDevice = deviceOptions.find(device => device.id === value);
        newTemplates[templateIndex].kits[kitIndex].name = selectedDevice?.name || '';
      }
      newTemplates[templateIndex].kits[kitIndex][field as keyof ShipmentKit] = value;
    } else if (field === 'weight') {
      newTemplates[templateIndex].weight = value;
    }
    setShipmentTemplates(newTemplates);
  };

  const handleNext = async () => {
    if (hasStandardShipments === null) return;
    
    try {
      const progress = JSON.parse(localStorage.getItem('trialProgress') || '{}');
      const currentTrialId = progress.trialId;

      if (!currentTrialId) {
        throw new Error('No trial ID found in progress');
      }

      const transformedTemplates = hasStandardShipments ? shipmentTemplates.map(template => ({
        dimensions: {
          length: parseFloat(template.dimensions.length),
          width: parseFloat(template.dimensions.width),
          height: parseFloat(template.dimensions.height)
        },
        weight: parseFloat(template.weight),
        contents: template.kits.map(kit => ({
          kit_id: kit.id,
          kit_name: kit.name,
          quantity: parseInt(kit.quantity),
          sample_type: deviceOptions.find(d => d.id === kit.id)?.type || 'other'
        }))
      })) : [];

      localStorage.setItem('trialProgress', JSON.stringify({
        ...progress,
        hasShipments: true,
        shipments: {
          hasStandardShipments,
          templates: transformedTemplates
        }
      }));

      navigate(`/create-trial/confirmation/${currentTrialId}`);
    } catch (err) {
      console.error('Failed to save shipment templates:', err);
    }
  };

  const handleDeleteTemplate = (indexToDelete: number) => {
    if (shipmentTemplates.length > 1) {
      setShipmentTemplates(shipmentTemplates.filter((_, index) => index !== indexToDelete));
    }
  };

  return (
    <PageLayout>
      <Subtitle>Do you have standard outbound shipments with {'>'}1 kit?</Subtitle>

      <ToggleContainer>
        <ToggleButton 
          selected={hasStandardShipments === true}
          onClick={() => setHasStandardShipments(true)}
        >
          Yes
        </ToggleButton>
        <ToggleButton 
          selected={hasStandardShipments === false}
          onClick={() => setHasStandardShipments(false)}
        >
          No
        </ToggleButton>
      </ToggleContainer>

      {hasStandardShipments && (
        <>
          <SubtitleSecondary>Build your Outbound Shipment(s)</SubtitleSecondary>
          
          {shipmentTemplates.map((template, templateIndex) => (
            <ShipmentContainer key={templateIndex}>
              <ShipmentHeader>
                <Label>Parcel Dimensions</Label>
                {templateIndex > 0 && (
                  <DeleteButton onClick={() => handleDeleteTemplate(templateIndex)}>
                    ✕
                  </DeleteButton>
                )}
              </ShipmentHeader>
              
              <InputSection>
                <Row>
                  <Column>
                    <Label>Parcel Dimensions</Label>
                    <DimensionsGroup>
                      <DimensionInput
                        value={template.dimensions.length}
                        onChange={(e) => handleInputChange(templateIndex, 'dimensions.length', e.target.value)}
                        placeholder="L"
                      />
                      <span>×</span>
                      <DimensionInput
                        value={template.dimensions.width}
                        onChange={(e) => handleInputChange(templateIndex, 'dimensions.width', e.target.value)}
                        placeholder="W"
                      />
                      <span>×</span>
                      <DimensionInput
                        value={template.dimensions.height}
                        onChange={(e) => handleInputChange(templateIndex, 'dimensions.height', e.target.value)}
                        placeholder="H"
                      />
                      <DimensionUnit>(in inches)</DimensionUnit>
                    </DimensionsGroup>
                  </Column>

                  <Column>
                    <Label>Weight</Label>
                    <WeightGroup>
                      <WeightInput
                        value={template.weight}
                        onChange={(e) => handleInputChange(templateIndex, 'weight', e.target.value)}
                      />
                      <WeightUnit>lbs.</WeightUnit>
                    </WeightGroup>
                  </Column>
                </Row>
              </InputSection>

              <InputSection>
                <DevicesHeader>
                  <Label>Kits</Label>
                  <Label style={{ width: '100px', textAlign: 'center' }}>Quantity</Label>
                  <div style={{ width: '40px' }} />
                </DevicesHeader>
                {template.kits.map((kit, kitIndex) => (
                  <KitRow key={kitIndex}>
                    <DeviceInputGroup>
                      <Select
                        value={kit.id}
                        onChange={(e) => handleInputChange(templateIndex, 'id', e.target.value, kitIndex)}
                      >
                        <option value="">Select Device ID</option>
                        {deviceOptions.map(device => (
                          <option key={device.id} value={device.id}>
                            {device.name}
                          </option>
                        ))}
                      </Select>
                      <KitInput
                        value={kit.name}
                        onChange={(e) => handleInputChange(templateIndex, 'name', e.target.value, kitIndex)}
                        placeholder="Kit Name"
                      />
                    </DeviceInputGroup>
                    <QuantityInput
                      value={kit.quantity}
                      onChange={(e) => handleInputChange(templateIndex, 'quantity', e.target.value, kitIndex)}
                      placeholder="Quantity"
                    />
                    {template.kits.length > 1 && (
                      <DeleteButton onClick={() => handleRemoveKit(templateIndex, kitIndex)}>
                        ✕
                      </DeleteButton>
                    )}
                  </KitRow>
                ))}
                <AddButton onClick={() => handleAddKit(templateIndex)}>
                  ⊕ Include Another Device
                </AddButton>
              </InputSection>
            </ShipmentContainer>
          ))}

          <AddTemplateButton onClick={handleAddTemplate}>
            ⊕ Build Another Shipment Template
          </AddTemplateButton>
        </>
      )}

      <NextButton onClick={handleNext}>
        Next
      </NextButton>
    </PageLayout>
  );
};

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0;
  width: fit-content;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #000;
`;

const ToggleButton = styled.button<{ selected?: boolean }>`
  padding: 16px 60px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${props => props.selected ? '#000' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#000'};

  &:hover {
    background-color: ${props => props.selected ? '#000' : '#f5f5f5'};
  }
`;

const SubtitleSecondary = styled(Subtitle)`
  margin-top: 40px;
  font-size: 18px;
`;

const ShipmentContainer = styled.div`
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 24px;
  margin: 20px auto;
  max-width: 800px;
`;

const InputSection = styled.div`
  margin-bottom: 32px;
`;

const Label = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;

const Row = styled.div`
  display: flex;
  gap: 48px;
  align-items: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DevicesHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 40px;

  > :first-of-type {
    flex: 1;
  }
`;

const DeviceInputGroup = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
`;

const DimensionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const WeightGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const KitRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
`;

const Select = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  min-width: 150px;
`;

const KitInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  min-width: 150px;
`;

const DimensionInput = styled.input`
  width: 60px;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  text-align: center;
`;

const WeightInput = styled.input`
  width: 80px;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
`;

const QuantityInput = styled.input`
  width: 100px;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  text-align: center;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 16px;

  &:hover {
    color: #000;
  }
`;

const AddTemplateButton = styled(AddButton)`
  margin: 24px auto;
`;

const ShipmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: #ff0000;
  }
`;

const DimensionUnit = styled.span`
  color: #666;
  margin-left: 8px;
`;

const WeightUnit = styled.span`
  color: #666;
`;

export default OutboundShipments; 